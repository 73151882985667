import { makeStyles } from '@material-ui/core';

export const topHeaderStyles = () =>
  makeStyles(() => ({
    top_header: {
      background: '#F4F4F4',
      opacity: 0.85,
      justifyContent: 'flex-end',
      height: '40px',
      color: '#000000',
    },

    username_block: {
      font: 'normal normal normal 14px/18px Arial',
      letterSpacing: '0.11px',
      fontWeight: 'bold',
      opacity: 1,
      marginRight: '30px',
      textTransform: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },

    profile_icon: {
      width: '26px',
      height: '26px',
      opacity: 1,
      paddingLeft: '7px',
    },

    lang_change_button: {
      font: 'normal normal normal 14px/18px Arial',
      letterSpacing: '0.11px',
      fontWeight: 'bold',
      opacity: 1,
      color: '#000000',
      // marginRight: "60px"
    },

    language_icon: {
      width: '28px',
      height: '28px',
      opacity: 1,
      paddingRight: '5px',
    },
    close_button: {
      width: '24px',
      height: '24px',
      opacity: 1,
      paddingRight: '0',
    },
  }));
