import { Grid, Select, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { monthGenerator } from '../../utils/CommonFunctions';
import { AREA_LABEL_DOB_MONTH, MMM } from '../../../../Constants';

const useStyles = CommonStyles();

const DateMonth = (props) => {
  const { t } = useTranslation();
  const { handleDateOfBirthChangeDropdown } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, handleBlur } = formikContext;
  return (
    <Grid item xs={3} sm={3} className="dob-select">
      <Select
        id="dob_month"
        name="dob_month"
        variant="outlined"
        fullWidth
        role="listbox"
        aria-label={`${t(AREA_LABEL_DOB_MONTH, {
          defaultValue: AREA_LABEL_DOB_MONTH,
        })}`}
        className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_month_year_select} ${CommonCssStyles.input_disabled_style}`}
        onBlur={handleBlur}
        onChange={(e) => handleDateOfBirthChangeDropdown(e)}
        value={values.dob_month}
        classes={{
          icon: CommonCssStyles.select_icon,
        }}
      >
        <MenuItem role="option" value="MMM">
          {t(MMM, { defaultValue: MMM })}
        </MenuItem>
        {monthGenerator().length !== 0
          ? monthGenerator().map((month) => (
              <MenuItem role="option" key={month} value={month}>
                {t(month, { defaultValue: month })}
              </MenuItem>
            ))
          : ''}
      </Select>
    </Grid>
  );
};
DateMonth.propTypes = {
  handleDateOfBirthChangeDropdown: PropTypes.func,
};

export default DateMonth;
