import { isMacOs } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import { Row } from '@livechat/ui-kit';
import { ChatWrapperCss } from '../utils/WrapperCss';
import { CommonStyles } from '../balance-cancellation/CommonStyles';

const useCommonStyles = CommonStyles();

const useChatWrapperCss = ChatWrapperCss();
const ProgressBar = (props) => {
  const WrapperCss = useChatWrapperCss();
  const { runningAWSConnect, showUploadingBar } = props;
  const CommoncssStyles = useCommonStyles();

  if (showUploadingBar && runningAWSConnect) {
    return (
      <Row align="center" className={CommoncssStyles.progress_row}>
        <div
          className={`progressBar ${
            isMacOs ? WrapperCss.button_width_IOS : WrapperCss.button_width_Windows
          }`}
        >
          <span className={CommoncssStyles.progress_design}>Uploading...</span>
          <div className="progressBar-color" />
        </div>
      </Row>
    );
  }
  return null;
};

ProgressBar.propTypes = {
  runningAWSConnect: PropTypes.bool,
  showUploadingBar: PropTypes.bool,
};

export default ProgressBar;
