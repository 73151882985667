import { Dialog } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { PropTypes } from 'prop-types';
import { DATEPICKERTHEME } from '../OverrideStyles';

export default function DatePickerField(props) {
  const formikContext = useFormikContext();
  const {
    onClose,
    date,
    open,
    handleDateOfBirthChange,
    language,
    minDateValue,
    maxDateValue,
    field,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      data-testid="closeTestId"
      open={open}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language ? enLocale : esLocale}>
        <ThemeProvider theme={DATEPICKERTHEME}>
          <DatePicker
            autoOk
            variant="static"
            data-testid="datePickerTest"
            minDate={minDateValue}
            maxDate={maxDateValue}
            value={date}
            onChange={(value) => {
              handleDateOfBirthChange(field, value, formikContext);
              handleClose();
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
}

DatePickerField.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.string,
  open: PropTypes.bool,
  handleDateOfBirthChange: PropTypes.func,
  language: PropTypes.bool,
  minDateValue: PropTypes.string,
  maxDateValue: PropTypes.string,
  field: PropTypes.string,
};
