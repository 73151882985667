import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { INVALID_DATE_ENG, MIN_AGE_MANDATORY } from '../../../../Constants';

const useStyles = CommonStyles();

const DateError = (props) => {
  const { t } = useTranslation();
  const { stepBackFlag, dateInvalid, DOBInvalid } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, errors, touched } = formikContext;
  return (
    <span className={CommonCssStyles.error_message_input}>
      {((errors.dob_date && touched.dob_date && !dateInvalid && !DOBInvalid) ||
        (stepBackFlag && errors.dob_date && values.dob_date !== '')) && (
        <span role="alert" tabIndex="-1">
          {t(errors.dob_date, { defaultValue: errors.dob_date })}
        </span>
      )}
      {((dateInvalid && !DOBInvalid && touched.dob_date) || (stepBackFlag && dateInvalid)) && (
        <span role="alert" tabIndex="-1">
          {t(INVALID_DATE_ENG, { defaultValue: INVALID_DATE_ENG, nsSeparator: '|' })}
        </span>
      )}
      {((!dateInvalid && DOBInvalid && touched.dob_date) || (stepBackFlag && DOBInvalid)) && (
        <span role="alert" tabIndex="-1">
          {t(MIN_AGE_MANDATORY, {
            defaultValue: MIN_AGE_MANDATORY,
            nsSeparator: '|',
          })}
        </span>
      )}
    </span>
  );
};
DateError.propTypes = {
  stepBackFlag: PropTypes.string,
  dateInvalid: PropTypes.bool,
  DOBInvalid: PropTypes.bool,
};

export default DateError;
