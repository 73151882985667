import { Grid, InputLabel, SvgIcon } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { ReactComponent as DatePickerIcon } from '../../images/datepicker.svg';
import DatePickerField from './DatePickerField';
import DateDay from './DateDay';
import DateMonth from './DateMonth';
import DateYear from './DateYear';
import DateError from './DateError';
import { DATE_OF_BIRTH, DATE_PICKER, REQUIRED } from '../../../../Constants';

const useStyles = CommonStyles();

const DateInput = (props) => {
  const { t } = useTranslation();
  const {
    open,
    englishBot,
    customerCheckIn,
    handleClose,
    stepBackFlag,
    dateInvalid,
    DOBInvalid,
    handleDateOfBirthChangeDropdown,
    handleClickOpen,
    handleDateOfBirthChange,
  } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, errors, touched } = formikContext;
  const todayDate = new Date();
  return (
    <Grid
      container
      item
      xs={12}
      md={customerCheckIn ? 12 : 4}
      style={customerCheckIn ? { padding: '0' } : { padding: '0 7px' }}
    >
      <Grid item xs={12} md={12} lg={customerCheckIn ? 12 : 10}>
        <InputLabel
          htmlFor="dateOfBirth"
          required
          aria-label={`${t(DATE_OF_BIRTH, { defaultValue: DATE_OF_BIRTH })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(DATE_OF_BIRTH, { defaultValue: DATE_OF_BIRTH })}:
        </InputLabel>
        <Grid
          container
          className={
            (errors.dob_date && touched.dob_date && !dateInvalid) ||
            dateInvalid ||
            (!dateInvalid && DOBInvalid)
              ? CommonCssStyles.ErrorEnableGrid
              : {}
          }
        >
          <DateDay handleDateOfBirthChangeDropdown={handleDateOfBirthChangeDropdown} />
          <DateMonth handleDateOfBirthChangeDropdown={handleDateOfBirthChangeDropdown} />
          <DateYear handleDateOfBirthChangeDropdown={handleDateOfBirthChangeDropdown} />
          <Grid container item xs={3} sm={3} className="dob-select-datepicker datePickerbtn">
            <Grid item xs={12} sm={12} className={CommonCssStyles.date_input}>
              <SvgIcon
                aria-label={t(DATE_PICKER, { defaultValue: DATE_PICKER })}
                tabIndex="0"
                role="graphic"
                titleAccess={t(DATE_PICKER, { defaultValue: DATE_PICKER })}
                component={DatePickerIcon}
                viewBox="0 0 29 29"
                onClick={handleClickOpen}
                className={CommonCssStyles.date_input_icon}
                data-testid="openDate_testid"
              />
            </Grid>
            <DatePickerField
              language={englishBot}
              handleDateOfBirthChange={handleDateOfBirthChange}
              date={values.dob_date}
              open={open}
              onClose={handleClose}
              maxDateValue={todayDate}
              field="dob_date"
            />
          </Grid>
        </Grid>
        <DateError stepBackFlag={stepBackFlag} dateInvalid={dateInvalid} DOBInvalid={DOBInvalid} />
      </Grid>
    </Grid>
  );
};
DateInput.propTypes = {
  open: PropTypes.bool,
  englishBot: PropTypes.bool,
  handleClose: PropTypes.func,
  stepBackFlag: PropTypes.string,
  dateInvalid: PropTypes.bool,
  DOBInvalid: PropTypes.bool,
  handleDateOfBirthChangeDropdown: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleDateOfBirthChange: PropTypes.func,
  customerCheckIn: PropTypes.bool,
};

export default DateInput;
