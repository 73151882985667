import { defaultTheme } from '@livechat/ui-kit';
import GenerateLexUserCookie from '../connectivity/GenerateLexUserCookie';

const lexUserCookie = GenerateLexUserCookie();
export const defaultCustomerData = {
  customerName: 'Customer',
  chatbotktc: lexUserCookie,
  clientId: process.env.REACT_APP_CLIENT_ID,
};
export const themeCSS = {
  ...defaultTheme,
  vars: {
    'primary-color': '#661C69',
    'secondary-color': '#F4F0EC',
  },
  TitleBar: {
    css: {
      width: 'auto',
      background: '#ffffff',
      borderRadius: '20px',
      color: '#333',
      font: 'normal normal bold 24px/28px Arial',
      fontSize: '24px',
      padding: '15px 15px 0 15px',
      alignItems: 'baseline',
    },
  },
  MessageGroup: {
    css: {
      marginBottom: '0',
      paddingLeft: '11px',
      paddingRight: '0.5em',
    },
  },
  Bubble: {
    css: {
      background: '#555858',
      border: '1px solid #555858',
      opacity: '1',
      marginBottom: '4px',
      display: 'inline-block',
    },
  },
  Message: {
    css: {
      marginBottom: '0.5em',
      marginRight: '0',
    },
  },
  MessageText: {
    css: {
      font: 'normal normal normal 14px/17px Arial',
      padding: '0',
    },
  },
  Avatar: {
    css: {
      width: '40px !important',
      height: '45px !important',
      minWidth: 'unset',
    },
  },
  TextInput: {
    css: {
      margin: '8px 0',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0',
      color: '#333333',
    },
  },
  TextComposer: {
    css: {
      borderRadius: '50px',
      margin: '0',
      border: '0.5px solid #000',
      opacity: '1',
      padding: '7px',
    },
    SendIcon: {
      css: {
        width: '18px',
        height: '16px',
      },
    },
  },
};

export const intentArrWithPegaTrue = [
  'CB_Pega_Intent',
  'CB_Pega_Intent_esp',
  'CB_Pega_Tax_Intent',
  'CB_Pega_TravelNote_Intent',
  'CB_Pega_TravelNote_Intent_esp',
  'CB_LiveAgent_Intent',
  'CB_LiveAgent_Intent_esp',
  'CB_PrivacyPolicy_Intent',
  'CB_PrivacyPolicy_Intent_esp',
  'CB_Servicecase_Intent',
  'CB_Pega_Tax_Intent_esp',
  'CB_PayBillIntent_one_one',
  'CB_PayBillIntent_one_one_esp',
];
export const intentArrWithPegaFalse = [
  'CB_Servicecase_Intent',
  'CB_Servicecase_Intent_esp',
  'CB_PrivacyPolicy_Intent',
  'CB_PrivacyPolicy_Intent_esp',
  'CB_GreetNameSlots_Intent',
  'CB_GreetNameSlots_Intent_esp',
  'CB_AccountBalanceIntent_three_one',
  'CB_AccountBalanceIntent_three_one_esp',
];
