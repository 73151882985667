import { Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';
import { CommonStyles } from '../CommonStyles';
import {
  EMAIL_ADDRESS,
  ENTER_EMAIL_ADDRESS,
  LET_US_KNOW_EMAIL,
  REQUIRED,
} from '../../../../Constants';

const useStyles = CommonStyles();

const EmailInput = (props) => {
  const { t } = useTranslation();
  const { inputHandleChange } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, errors, touched, handleBlur } = formikContext;
  const outlinedInputsError = {
    background: '#FD00000D',
  };
  return (
    <Grid item container xs={12} className={CommonCssStyles.fields_grid}>
      <Grid container item xs={12} md={8} className={CommonCssStyles.email_padding}>
        <Grid
          item
          xs={12}
          md={10}
          className={
            (errors.personEmail && touched.personEmail) ||
            (errors.personEmail && values.personEmail !== '')
              ? CommonCssStyles.ErrorEnableGrid
              : {}
          }
        >
          <InputLabel
            htmlFor="personEmail"
            required
            aria-label={`${t(LET_US_KNOW_EMAIL, {
              defaultValue: LET_US_KNOW_EMAIL,
            })}: ${t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })} ${t(REQUIRED, {
              defaultValue: REQUIRED,
            })}`}
            className={CommonCssStyles.label_title}
          >
            {t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })}:
          </InputLabel>
          <OutlinedInput
            id="personEmail"
            name="personEmail"
            className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
            placeholder={t(ENTER_EMAIL_ADDRESS, { defaultValue: ENTER_EMAIL_ADDRESS })}
            fullWidth
            inputProps={{
              inputMode: isMobile || isTablet ? 'email' : 'text',
            }}
            value={values.personEmail}
            onBlur={handleBlur}
            onChange={(e) => inputHandleChange(e)}
            autoComplete="off"
            style={
              (errors.personEmail && touched.personEmail) ||
              (errors.personEmail && values.personEmail !== '')
                ? outlinedInputsError
                : {}
            }
          />
          <span className={CommonCssStyles.error_message_input}>
            {((errors.personEmail && touched.personEmail) ||
              (errors.personEmail && values.personEmail !== '')) && (
              <span role="alert" tabIndex="-1" data-testid="emailError">
                {t(errors.personEmail, { defaultValue: errors.personEmail })}
              </span>
            )}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};
EmailInput.propTypes = {
  inputHandleChange: PropTypes.func,
};

export default EmailInput;
