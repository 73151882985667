import { makeStyles } from '@material-ui/core';

export const WrapupStyles = () =>
  makeStyles((theme) => ({
    main_modal_container: {
      padding: '15px 40px',
      [theme.breakpoints.down('650')]: {
        padding: '10px',
      },
    },
    step_two_margin: {
      margin: '20px 0',
    },
    step_two_row: { display: 'block', textAlign: 'center' },
    text_position: {
      width: '68%',
      height: '90px',
      display: 'inline-flex',
      marginTop: '10px',
    },
    text_design: {
      alignItems: 'center',
      width: '100%',
    },
    text_padding: {
      padding: '0.5em',
    },
  }));
