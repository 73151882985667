import { useState, useEffect } from 'react';
import { Message, IconButton, Row } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import thumbsUp from '../images/Thumbs_up_grey_line.png';
import thumbsupActive from '../images/Thumbs_up_purple_line_grey_fill.png';
import thumbsDown from '../images/Thumbs_down_grey_line.png';
import thumbsdownActive from '../images/Thumbs_down_purple_line_grey_fill.png';
import {
  BACK_TO_CHAT_ENG,
  BACK_TO_CHAT_ESP,
  END_CHAT_ENG,
  END_CHAT_ESP,
  ENTER_EMAIL_ADDRESS,
  ENTER_EMAIL_ADDRESS_ESP,
  ERROR_MESSAGE_TRANSCRIPT_ENG,
  ERROR_MESSAGE_TRANSCRIPT_ESP,
  FEEDBACK_WILL_HELP_ENG,
  FEEDBACK_WILL_HELP_ESP,
  SEND_CHAT_TRANSCRIPT_ENG,
  SEND_CHAT_TRANSCRIPT_ESP,
} from '../../../Constants';
import { WrapupStyles } from './WrapupStyles';

const useCommonStyles = WrapupStyles();

const StepTwo = (props) => {
  const { continueChat, endChat, sendToLex, englishBot, wrapEmail } = props;
  const WrapupCssStyles = useCommonStyles();

  const [goodRateImage, setGoodRateImage] = useState(thumbsUp);
  const [goodRatePress, setGoodRatePress] = useState(false);
  const [checked, setChecked] = useState(false);
  const [badRateImage, setBadRateImage] = useState(thumbsDown);
  const [badRatePress, setBadRatePress] = useState(false);
  const [emailValid, setEmailValid] = useState(wrapEmail !== '');
  const [emailValue, setEmailValue] = useState(wrapEmail !== '' ? wrapEmail : '');
  const defaultContent = englishBot ? FEEDBACK_WILL_HELP_ENG : FEEDBACK_WILL_HELP_ESP;
  const defaultEndChat = englishBot ? END_CHAT_ENG : END_CHAT_ESP;
  const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
  const emailInputPlaceholder = englishBot ? ENTER_EMAIL_ADDRESS : ENTER_EMAIL_ADDRESS_ESP;
  const checkboxLabel = englishBot ? SEND_CHAT_TRANSCRIPT_ENG : SEND_CHAT_TRANSCRIPT_ESP;
  const errorMessage = englishBot ? ERROR_MESSAGE_TRANSCRIPT_ENG : ERROR_MESSAGE_TRANSCRIPT_ESP;
  const handleThumbsup = () => {
    setGoodRateImage(thumbsupActive);
    setBadRateImage(thumbsDown);
    setGoodRatePress(true);
    setBadRatePress(false);
    sendToLex('thumbsup');
  };
  const handleThumbsdown = () => {
    setBadRateImage(thumbsdownActive);
    setGoodRateImage(thumbsUp);
    setBadRatePress(true);
    setGoodRatePress(false);
    sendToLex('thumbsdown');
  };
  const checkboxClickHandler = () => {
    setChecked(!checked);
  };
  const emailHandler = (e) => {
    if (
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        e.target.value
      )
    ) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
    setEmailValue(e.target.value);
  };
  const emailErrorMessageNoDisplay = {
    display: 'none',
  };
  const eemailErrorMessageDisplay = {
    margin: '0 auto',
    color: '#DF0000',
    marginTop: '5px',
  };
  const emailOpacity = {
    opacity: '0.5',
    pointerEvents: 'none',
  };
  const emailOpacityFull = {
    opacity: '1',
  };
  useEffect(() => {
    const wrapupBtns = document.getElementsByClassName('wrapupTwoBtn');
    if (wrapupBtns && document.getElementsByClassName('wrapupTwoBtn').length > 0) {
      wrapupBtns[0].firstChild.focus();
    }
  }, []);
  const endChatHandler = () => {
    endChat();
  };
  return (
    <Message
      className="wrapup-containerTwo"
      style={{
        textAlign: 'center',
      }}
    >
      <div className={WrapupCssStyles.text_design}>
        <div className="endchatHeading">{defaultContent}</div>
        <div className={WrapupCssStyles.text_position}>
          <div
            className="wrapupTwoBtn"
            style={{
              width: '50%',
            }}
          >
            <div
              className={WrapupCssStyles.text_padding}
              onClick={handleThumbsup}
              tabIndex="102"
              data-testid="handleThumbsup_testid"
              aria-pressed={goodRatePress}
            >
              <img alt="thumbs up" width="74" height="59" src={goodRateImage} />
            </div>
          </div>
          <div
            style={{
              width: '50%',
              marginTop: '20px',
            }}
          >
            <div
              className={WrapupCssStyles.text_padding}
              onClick={handleThumbsdown}
              tabIndex="102"
              data-testid="handleThumbsdown_testid"
              aria-pressed={badRatePress}
            >
              <img alt="thumbs down" width="74" height="59" src={badRateImage} />
            </div>
          </div>
        </div>
        <div className={WrapupCssStyles.step_two_margin}>
          <label className="checkbox-label" htmlFor="consent1">
            <input
              type="checkbox"
              name="consent1"
              id="consent1"
              tabIndex="102"
              data-testid="checkbox_testid_test1"
              checked={checked}
              onChange={checkboxClickHandler}
            />
            <span
              style={{ marginLeft: '0px' }}
              className="checkbox-custom rectangular"
              data-testid="checkbox_testid_test1_span"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  checkboxClickHandler();
                }
              }}
            />
            <span className="checkbox-label-text">{checkboxLabel}</span>
          </label>
          {checked && (
            <div>
              {' '}
              <input
                id="email-field"
                className="email-field"
                type="text"
                placeholder={emailInputPlaceholder}
                tabIndex="102"
                data-testid="emailHandler_testid"
                value={emailValue}
                onChange={emailHandler}
              />
              <div style={emailValid ? emailErrorMessageNoDisplay : eemailErrorMessageDisplay}>
                <Row align="center" className={WrapupCssStyles.step_two_row}>
                  <span>{errorMessage}</span>
                </Row>
              </div>
            </div>
          )}
        </div>
        <div
          className="wrapup-btn"
          style={{
            width: '100%',
            margin: '0 0 25px 0',
          }}
        >
          <IconButton onClick={continueChat} tabIndex="102" data-testid="continuechat_id">
            {defaultContinueChat}
          </IconButton>
          <IconButton
            className="end-btn"
            onClick={endChatHandler}
            data-testid="endbtn_testid"
            tabIndex="102"
            style={checked && !emailValid ? emailOpacity : emailOpacityFull}
          >
            {defaultEndChat}
          </IconButton>
        </div>
      </div>
    </Message>
  );
};

StepTwo.propTypes = {
  continueChat: PropTypes.func,
  endChat: PropTypes.func,
  englishBot: PropTypes.bool,
  wrapEmail: PropTypes.bool,
  sendToLex: PropTypes.bool,
};

export default StepTwo;
