const LEXUSERID = 'LEXUSERID';

export default function GenerateLexUserId() {
  if (localStorage.getItem(LEXUSERID) != null) {
    return localStorage.getItem(LEXUSERID);
  }
  const lexUserId = `mediumBot${Date.now()}`;
  localStorage.setItem(LEXUSERID, lexUserId);
  return lexUserId;
}
