import { useTranslation } from 'react-i18next';
import { IconButton, CloseIcon, TitleBar } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import lung from '../images/lungIcon.png';
import { ENG, ESP, LANGUAGE_SELECTION } from '../../../Constants';
import { CommonStyles } from '../balance-cancellation/CommonStyles';

const useCommonStyles = CommonStyles();

const ChatHeader = (props) => {
  const CommoncssStyles = useCommonStyles();

  const { t } = useTranslation();
  const { englishBot, changeLanguage, minimize, closeEvent } = props;
  const minimizeClicked = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.size(100, 140);
    }
    minimize();
  };
  return (
    <TitleBar
      leftIcons={[
        <div
          style={{
            display: 'inline-flex',
          }}
          className="leftIcons"
          key="div-wrapper"
        >
          <IconButton
            key="minimize"
            className={CommoncssStyles.chat_header_icon}
            aria-label={LANGUAGE_SELECTION}
            onClick={changeLanguage}
            tabIndex="100"
            data-testid="changeLanguage_testid"
          >
            <img
              src={lung}
              alt="oriental language icon"
              width="17"
              height="17"
              className={CommoncssStyles.chat_head_img}
            />
            <span className="languageLink">{englishBot ? ESP : ENG}</span>
          </IconButton>
        </div>,
      ]}
      rightIcons={[
        <div className={CommoncssStyles.chat_header_right_icon} key="div">
          <IconButton
            key="minimize"
            onClick={minimizeClicked}
            style={{
              padding: '0',
              marginRight: '12px',
            }}
            aria-label="minimize"
            data-testid="minClickbtn_testid"
            tabIndex="107"
          >
            <i
              className="material-icons"
              style={{
                color: '#661c69',
              }}
            >
              horizontal_rule
            </i>
          </IconButton>
          <IconButton
            key="close"
            //   onClick={minimize}

            className={CommoncssStyles.icon_button}
            onClick={closeEvent}
            data-testid="closeevent_testid"
            aria-label="close"
            tabIndex="106"
          >
            <CloseIcon
              className="svg-custom"
              color="#661C69"
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </IconButton>
        </div>,
      ]}
      title={[
        <h1 className="titleHeading" key="title">
          {t('Chat')} <sup className={CommoncssStyles.sup_design}>Beta</sup>
        </h1>,
      ]}
    />
  );
};

ChatHeader.propTypes = {
  englishBot: PropTypes.bool,
  changeLanguage: PropTypes.func,
  minimize: PropTypes.func,
  closeEvent: PropTypes.func,
};
export default ChatHeader;
