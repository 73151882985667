import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { IconButton } from '@livechat/ui-kit';
import {
  MORE_THAN_TEN_MIN_ENG,
  MORE_THAN_TEN_MIN_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  SHARE_MY_CONTACT_DETAILS_ENG,
  SHARE_MY_CONTACT_DETAILS_ESP,
} from '../../../Constants';

const StepOne = (props) => {
  const { t } = useTranslation();
  const { handleWrapUpStepOneYes, handleWrapUpStepOneNo, englishBot, message, handleMessage } =
    props;
  const defaultContent = englishBot ? 'Yes' : 'Sí';
  const defaultContent2 = englishBot ? SHARE_MY_CONTACT_DETAILS_ENG : SHARE_MY_CONTACT_DETAILS_ESP;
  const backToChatbot = () => {
    setTimeout(() => {
      handleMessage({ text: defaultContent2, value: 'PEGA_CONNECT' }, true);
    }, 2000);
  };
  if (message === NEED_ANYTHING_ELSE_ENG || message === NEED_ANYTHING_ELSE_ESP) {
    return (
      <div
        className="quick-replies"
        style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
      >
        <IconButton
          onClick={handleWrapUpStepOneYes}
          tabIndex="102"
          data-testid="defaultToChatbot_id"
        >
          {defaultContent}
        </IconButton>
        <IconButton onClick={handleWrapUpStepOneNo} tabIndex="102" data-testid="Chatbot_id">
          {t('No')}
        </IconButton>
      </div>
    );
  }
  if (message?.includes(MORE_THAN_TEN_MIN_ENG) || message?.includes(MORE_THAN_TEN_MIN_ESP)) {
    return (
      <div
        className="quick-replies"
        style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
      >
        <IconButton onClick={backToChatbot} tabIndex="102" data-testid="backToChatbot_id">
          {defaultContent2}
        </IconButton>
      </div>
    );
  }
  return null;
};
StepOne.propTypes = {
  englishBot: PropTypes.bool,
  handleWrapUpStepOneYes: PropTypes.func,
  handleWrapUpStepOneNo: PropTypes.func,
  message: PropTypes.string,
  handleMessage: PropTypes.func,
};
export default StepOne;
