import { makeStyles } from '@material-ui/core';

export const footerStyles = () =>
  makeStyles(() => ({
    footer: {
      background: '#F4F4F4',
      opacity: 0.85,
      justifyContent: 'center',
      height: '38px',
      color: '#000000',
      alignItems: 'center',
      alignContent: 'center',
      display: 'flex',
    },

    copyrights_text: {
      textAlign: 'center',
      font: 'normal normal normal 12px / 15px Arial',
      letterSpacing: '0.08px',
      color: '#000000',
      opacity: 1,
      fontWeight: 'bold',
    },

    footer_position: {
      // position: "absolute",
      width: '100%',
      bottom: 0,
    },
  }));
