import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import { CommonStyles } from './CommonStyles';
import {
  CONSENT_DESC1,
  CONSENT_DESC2,
  CONSENT_EMAIL_INFO,
  PROVIDE_YOUR_CONSENT,
  USVI_WEBFORM,
} from '../../../Constants';

const useCommonStyles = CommonStyles();
function StepThree(props) {
  const { t } = useTranslation();
  const CommonCssStyles = useCommonStyles();
  const { webFormType } = props;
  const formikContext = useFormikContext();
  const { values, errors, touched, handleChange, handleBlur } = formikContext;
  return (
    <Grid container xs={12} style={{ margin: '10px 0' }}>
      <Grid item xs={12} style={{ margin: '12px 0' }}>
        <Grid item xs={12}>
          <Typography
            variant="div"
            id="dialog2Title"
            className={CommonCssStyles.form_heading_info}
            style={{ margin: '15px' }}
          >
            {t(PROVIDE_YOUR_CONSENT, { defaultValue: PROVIDE_YOUR_CONSENT })}:
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ margin: '12px 0' }}>
        <div>
          <label className="checkbox-label" htmlFor="consent">
            <input
              type="checkbox"
              name="consent"
              id="consent"
              tabIndex="0"
              data-testid="checkbox_testid1"
              style={{ outline: 'none' }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  formikContext.setFieldValue('consent', !values.consent);
                }
              }}
              // autoFocus
              checked={values.consent}
              value={values.consent}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <span
              style={{ marginLeft: '2px' }}
              className="checkbox-custom rectangular"
              data-testid="checkbox_testid2"
              tabIndex="-1"
              // aria-label={webFormType === "PUERTO_RICO_WEBFORM" ? t('Consent Desc 1', { defaultValue: "This application has a $10.00 cost. I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585." }) : t('Consent Desc 2', { defaultValue: "The first payoff satement request and any update or correction to that original request will be issued at no cost to the client. Any additional payoff statement requests within a six (6) month period will be charged at $25.00.  I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585." }) }
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  formikContext.setFieldValue('consent', !values.consent);
                }
              }}
            />
            {webFormType === 'PUERTO_RICO_WEBFORM' && (
              <span
                className={`${CommonCssStyles.checkbox_label_style} ${CommonCssStyles.checkbox_content_position}`}
              >
                *{' '}
                {t(CONSENT_DESC1, {
                  defaultValue: CONSENT_DESC1,
                  nsSeparator: '|',
                })}
              </span>
            )}
            {webFormType === USVI_WEBFORM && (
              <span
                className={`${CommonCssStyles.checkbox_label_style} ${CommonCssStyles.checkbox_content_position}`}
              >
                *{' '}
                {t(CONSENT_DESC2, {
                  defaultValue: CONSENT_DESC2,
                  nsSeparator: '|',
                })}
              </span>
            )}
          </label>
          <span className={CommonCssStyles.error_message_input} style={{ textAlign: 'left' }}>
            {errors.consent && touched.consent && (
              <span role="alert" tabIndex="-1">
                {t(errors.consent, { defaultValue: errors.consent })}
              </span>
            )}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} style={{ margin: '12px 0' }}>
        <div>
          <label className="checkbox-label" htmlFor="consentEmail">
            <input
              type="checkbox"
              name="consentEmail"
              id="consentEmail"
              data-testid="checkbox_testid3"
              style={{ outline: 'none' }}
              tabIndex="0"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  formikContext.setFieldValue('consentEmail', !values.consent);
                }
              }}
              // autoFocus
              checked={values.consentEmail}
              value={values.consentEmail}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <span
              style={{ marginLeft: '2px' }}
              className="checkbox-custom rectangular"
              tabIndex="-1"
              data-testid="checkbox_testid4"
              // aria-label={t('Consent email info', { defaultValue: "I consent to receive this payoff statement electronically. This consent applies solely and exclusively to the sending of this request. I acknowledge that I have the right to withdraw this electronic consent at any time and receive a paper copy of the payoff statement. To withdraw consent, request a copy of the document and change the email address, I must contact 787.767.8585 or send an email to servicio.hipotecas@orientalbank.com. We will send your payoff statement in PDF format. A PDF reader program may be required to view and print PDF files." })}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  formikContext.setFieldValue('consentEmail', !values.consent);
                }
              }}
            />
            <span
              className={`${CommonCssStyles.checkbox_label_style} ${CommonCssStyles.checkbox_content_position}`}
            >
              *{' '}
              {t(CONSENT_EMAIL_INFO, {
                defaultValue: CONSENT_EMAIL_INFO,
                nsSeparator: '|',
              })}
            </span>
          </label>
          <span className={CommonCssStyles.error_message_input} style={{ textAlign: 'left' }}>
            {errors.consentEmail && touched.consentEmail && (
              <span role="alert" tabIndex="-1">
                {t(errors.consentEmail, { defaultValue: errors.consentEmail })}
              </span>
            )}
          </span>
        </div>
      </Grid>
    </Grid>
  );
}
StepThree.propTypes = {
  webFormType: PropTypes.string,
};

export default StepThree;
