import { makeStyles } from '@material-ui/core';

export const VerifyFormCss = () =>
  makeStyles((theme) => ({
    form_heading_wrapper: {
      padding: '5px 0',
    },
    form_heading: {
      textAlign: 'left',
      font: 'normal normal bold 18px/21px Arial',
      letterSpacing: '0.12px',
      color: '#000000',
      opacity: 1,
      padding: '0 0 10px 0',
    },
    form_heading_customerCheckIn: {
      textAlign: 'left',
      font: 'normal normal normal 14px/21px Arial',
      letterSpacing: '0.12px',
      color: '#000000',
      opacity: 1,
      padding: '0 0 10px 0',
    },
    form_fields_padding: {
      padding: '5px 0',
    },
    error_message_input: {
      display: 'block',
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: '#DF0000',
      opacity: 1,
      textAlign: 'right',
      height: '16px',
      boxSizing: 'border-box',
      marginTop: '3px',
    },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: '#DF0000',
      opacity: 1,
    },
  }));
export const overrideVerifyFormCss = makeStyles((theme) => ({
  field_wrapper: {
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      paddingBottom: '5px',
      color: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #661C69',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #661C69',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '7.5px 7px',
      fontSize: '14px',
    },
  },
}));
