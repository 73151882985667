import { makeStyles } from '@material-ui/core';

export const stepperStyles = () =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
      // [theme.breakpoints.down('650')]: {
      //     width: "40px",
      //     height: "40px",
      // }
    },

    zero_Padding: {
      padding: '0',
      width: '50%',
      margin: '20px 10px',
      [theme.breakpoints.down('750')]: {
        width: '100%',
        margin: '0',
      },
      [theme.breakpoints.down('650')]: {
        width: '92%',
        margin: 'auto',
      },
    },
  }));

export const stepperColorlibStepIconStyles = () =>
  makeStyles((theme) => ({
    root: {
      // backgroundColor: '#575159',
      zIndex: 1,
      color: '#000',
      background: '#fff',
      width: 24,
      height: 24,
      display: 'flex',
      font: 'normal normal bold 12px/14px Arial',
      letterSpacing: '0.08px',
      fontWeight: '800',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      border: '8px solid #6D6E70',
      [theme.breakpoints.down('650')]: {
        width: '20px',
        height: '20px',
      },
    },
    active: {
      // backgroundImage:
      // 'linear-gradient( 136deg, #661C69 0%, #661C69 50%,#661C69 100%)',
      border: '8px solid #F5821F',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      // backgroundImage:
      // 'linear-gradient( 136deg, #661C69 0%, #661C69 50%, #661C69 100%)',
      // border: "8px solid #F5821F" ,
    },
  }));
