import './Spinner.css';
import { PropTypes } from 'prop-types';

const Spinner = (props) => {
  const { agentTypingFlag, englishBot } = props;
  const messageContainer = document.getElementById('messageContainer');
  if (messageContainer) {
    messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
  }
  return (
    <div className="spinner">
      {agentTypingFlag && (
        <span className="spinnerflag">{englishBot ? 'Typing' : 'escribiendo'}</span>
      )}
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

Spinner.propTypes = {
  englishBot: PropTypes.bool,
  agentTypingFlag: PropTypes.bool,
};

export default Spinner;
