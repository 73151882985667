import axios from 'axios';
import { isMobile } from 'react-device-detect';
import GenerateLexUserCookie from '../connectivity/GenerateLexUserCookie';
import GenerateLexUserId from '../connectivity/GenerateLexUserId';
import { ADDITIONAL_DETAILS, CUSTOMER_MESSAGE, MESSAGE_BODY } from '../Constants';

const lexUserId = GenerateLexUserId();
const lexUserCookie = GenerateLexUserCookie();
export const base64Authorization = Buffer.from(
  `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
).toString('base64');
export const getParameters = (
  message,
  slotToElicit,
  englishBot,
  sessionID,
  chatDeviceType,
  chatDeviceOS,
  travelCountryRepeatFlag
) => {
  let inputMessage = '';
  if (
    slotToElicit === ADDITIONAL_DETAILS ||
    slotToElicit === MESSAGE_BODY ||
    slotToElicit === CUSTOMER_MESSAGE
  ) {
    inputMessage = `<slot>${message}<slot>`;
  } else {
    inputMessage = message;
  }
  return {
    botAliasId: process.env.REACT_APP_BOT_ALIAS_ID,
    botId: process.env.REACT_APP_BOT_ID,
    inputText: inputMessage,
    locale: englishBot ? 'en_US' : 'es_US',
    sessionId: sessionID || '',
    requestAttributes: {
      userId: lexUserId,
      deviceType: chatDeviceType,
      DeviceOS: chatDeviceOS,
      chatbotktc: lexUserCookie,
      travelCountryRepeatFlag,
    },
  };
};
export const downloadAttachmentFile = async (id, token) => {
  const data = {
    attachmentId: id,
    connectionToken: token,
  };
  const response = await axios.post(
    process.env.REACT_APP_TOKEN_SERVICE_URL,
    {
      clientId: process.env.REACT_APP_CLIENT_ID,
    },
    {
      headers: {
        Authorization: `Basic ${base64Authorization}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  const innerResponse = await axios.post(process.env.REACT_APP_DOWNLOAD_FILE_CALL_API, data, {
    headers: {
      Authorization: `Bearer ${response.data.access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return innerResponse.data.presignedURL;
};

export const timeConvert = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  minutes = String(minutes).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  return `${hours}:${minutes}`;
};
export const disableAllChatButtons = () => {
  const elems = document.getElementsByClassName('quick-reply-button');
  if (elems && elems.length > 0) {
    for (const element of elems) {
      element.disabled = true;
    }
  }
};

export const toggleChatbotContainerSize = (popupRunningFlag) => {
  if (!popupRunningFlag) {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((obj) => {
        const winHeight = (obj.windowHeight * 95) / 100;
        if (isMobile) {
          window.parentIFrame.size(winHeight, obj.windowWidth);
        } else {
          window.parentIFrame.size(winHeight, 470);
        }
      });
    }
  }
};

export const deleteSessioApiCall = (englishBot, sessionID) => {
  axios
    .post(
      process.env.REACT_APP_TOKEN_SERVICE_URL,
      {
        clientId: process.env.REACT_APP_CLIENT_ID,
      },
      {
        headers: {
          Authorization: `Basic ${base64Authorization}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((response) => {
      axios.post(
        `${process.env.REACT_APP_DELETE_SESSION_API}`,
        {
          botAliasId: process.env.REACT_APP_BOT_ALIAS_ID,
          botId: process.env.REACT_APP_BOT_ID,
          locale: englishBot ? 'en_US' : 'es_US',
          sessionId: sessionID,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    })
    .catch();
};
export const focusInChatbot = () => {
  const messageGroup = document.getElementById('messageGroup');
  const messageTextarea = document.getElementById('messageText');
  if (
    messageGroup &&
    messageGroup.lastChild.lastElementChild.querySelectorAll('.quick-replies').length > 0
  ) {
    messageGroup.lastChild.lastElementChild
      .querySelectorAll('.quick-replies')[0]
      .firstChild.focus();
  } else if (messageTextarea) {
    messageTextarea.focus();
  }
};
