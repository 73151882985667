import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FOOTER_TEXT_SMALL } from '../../../../Constants';
import { footerStyles } from './FooterStyles';

const useStyles = footerStyles();
const Footer = () => {
  const { t } = useTranslation();
  const styledClasses = useStyles();
  return (
    <div style={{ marginTop: 'auto' }}>
      <Grid container className={styledClasses.footer}>
        <div className={styledClasses.footer_position}>
          <div tabIndex="0" className={styledClasses.copyrights_text} title="footerDescription1">
            {t(`Footer`, {
              defaultValue: FOOTER_TEXT_SMALL,
            })}
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Footer;
