// Connect Lex Process
import * as React from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { isMobile, isAndroid, isIOS, isWindows } from 'react-device-detect';
import { ThemeProvider, FixedWrapper } from '@livechat/ui-kit';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import Maximized from '../components/chat/Maximized';
import Minimized from '../components/chat/Minimized';
import {
  defaultCustomerData,
  themeCSS,
  intentArrWithPegaTrue,
  intentArrWithPegaFalse,
} from './AppData';
import {
  base64Authorization,
  getParameters,
  downloadAttachmentFile,
  timeConvert,
  disableAllChatButtons,
  toggleChatbotContainerSize,
  deleteSessioApiCall,
  focusInChatbot,
} from './CommonFunction';
import {
  ALL_AGENTS_BUSY_EST_TIME_ENG,
  ALL_AGENTS_BUSY_EST_TIME_ESP,
  CHAT_WITH_BOT_ENDED_ENG,
  CHAT_WITH_BOT_ENDED_ESP,
  CONNECTING_TO_LIVR_AGENT_ENG,
  CONNECTING_TO_LIVR_AGENT_ESP,
  CONTACT_US_ENG,
  CONTACT_US_ESP,
  CONTACT_YOU_SOON_ENG,
  CONTACT_YOU_SOON_ESP,
  ENG,
  ESP,
  ESTIMATED_WAIT_TIME_IS,
  ESTIMATED_WAIT_TIME_IS_ESP,
  LIVE_AGENT_CHAT_ENDED_ENG,
  LIVE_AGENT_CHAT_ENDED_ESP,
  MORE_THAN_FIVE_ENG,
  MORE_THAN_FIVE_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  SHOW_FEEDBACK,
  SOMETHING_WENT_WRONG_ENG,
  SOMETHING_WENT_WRONG_ESP,
  SORRY_AGENTS_ARE_BUSY_ENG,
  SORRY_AGENTS_BUSY_ESP,
  TRY_AGAIN_LATER_ENG,
  TRY_AGAIN_LATER_ESP,
  WAIT_FOR_LIVE_AGENT_ENG,
  WAIT_FOR_LIVE_AGENT_ESP,
} from '../Constants';

const { AWS } = window;
const { connect, AmazonCustomChatWidget } = window;
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //  IdentityPoolId: 'us-east-1:5e406517-f2fa-4ea3-a014-38f4f113140b', //dev
  // IdentityPoolId: 'us-east-1:02490fc9-5c89-4efb-9cda-6202de1d3c19', //qa
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
});
const urlParams = new URLSearchParams(window.location.search);
const browserLanguage = urlParams.get('dc_language');
const chatIntent = urlParams.get('chat_intent');
const deviceType = isMobile ? 'Mobile' : 'Desktop';
let deviceOS = '';
if (isMobile && isAndroid) {
  deviceOS = 'Android';
} else if (isMobile && isIOS) {
  deviceOS = 'IOS';
} else {
  deviceOS = '';
}
let customerChatSession = {};
let dataSlotsCustomer = {};
const cusData = defaultCustomerData;
let intialWaitingTime = 0;
class App extends React.Component {
  theme = themeCSS;

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      messages: [],
      englishBot: browserLanguage !== 'ES',
      btnIntentTrigger: chatIntent || '',
      originalBotLanguage: browserLanguage !== 'ES',
      lexError: false,
      sessionID: '',
      userEmail: '',
      pegaIntent: false,
      defaultTextContent: '',
      chatDeviceType: deviceType !== '' ? deviceType : '',
      chatDeviceOS: isWindows ? 'Windows' : deviceOS,
      popupRunning: false,
      slotToElicit: '',
      liveAgent: false,
      liveAgentJoined: false,
      liveAgentDisconnected: false,
      waitingForLiveAgent: false,
      allowUploadDoc: false,
      agentTypingFlag: false,
      awsConnectAgentName: '',
      customerDetails: {},
      askForTimeAgain: false,
      hideOnCustomerType: false,
      verfiedCustomer: false,
      connectionSucessful: false,
      sendHiFromApp: false,
      gotGreeting: false,
      reConnect: false,
      formInProcess: false,
      spinnerTrigger: false,
      cardValidFlag: false,
      isServiceCase: false,
      sspSiteFlag: '',
    };
    const { i18n } = this.props;
    i18n.changeLanguage(browserLanguage === 'ES' ? ESP : ENG);
  }

  componentDidMount() {
    this.handlerLiveAgentTrigger();
    // this.AWSConnectInit(cusData);
    window.addEventListener('message', (event) => {
      if (!event.origin.includes('.orientalbank.com')) return;
      if (event.data !== 'undefined' && !event.data.includes('[iFrameSizer]')) {
        if (event.data.includes('&')) {
          const value = event.data?.split('&');
          this.setState({ btnIntentTrigger: value[0], sspSiteFlag: value[1] });
        } else {
          this.setState({ btnIntentTrigger: event.data });
        }
      }
    });
    this.intervalId = window.setInterval(() => {
      let message = {};
      const { messages, customerDetails, liveAgent, askForTimeAgain, connectionSucessful } =
        this.state;
      if (messages.length > 0) {
        message = messages[messages.length - 1];
        const diff = moment().diff(moment(message.messageDate), 'seconds');
        this.wrapUpcallFunction(message, diff);
        this.waitTimeLimitOverForLiveAgent(message, diff);
        if (
          (message.message.includes(CONNECTING_TO_LIVR_AGENT_ENG) ||
            message.message.includes(CONNECTING_TO_LIVR_AGENT_ESP)) &&
          !liveAgent &&
          !connectionSucessful
        ) {
          dataSlotsCustomer = message.Attributes;
          // console.log(dataSlotsCustomer);
          this.setState({
            waitingForLiveAgent: true,
            connectionSucessful: true,
            spinnerTrigger: false,
          });
          this.AWSConnectInit(dataSlotsCustomer);
          // this.AWSConnectInit(customerDetails);
        }

        if (askForTimeAgain) {
          this.sendAgentWaitingTimeMessageTrigger(intialWaitingTime, diff);
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  wrapUpcallFunction = (message, diff) => {
    const { englishBot, lexError, popupRunning, liveAgentJoined, liveAgent, formInProcess } =
      this.state;
    if (
      diff > 149 &&
      !popupRunning &&
      !liveAgentJoined &&
      !liveAgent &&
      !formInProcess &&
      !lexError
    ) {
      if (
        message.message !== (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP) &&
        message.message !== SHOW_FEEDBACK &&
        message.message.includes(CONTACT_YOU_SOON_ENG) === false &&
        message.message.includes(CONTACT_YOU_SOON_ESP) === false &&
        (message.showWrapupStepTwo === false || undefined === message.showWrapupStepTwo)
      ) {
        this.handleWrapupMessage(
          {
            text: englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP,
            value: '',
            showYesNo: true,
            msgFromLex: true,
            messageDate: new Date(),
          },
          false
        );
      }
    }
    if (
      diff > 29 &&
      !popupRunning &&
      !formInProcess &&
      !liveAgentJoined &&
      message.message === (englishBot ? NEED_ANYTHING_ELSE_ENG : NEED_ANYTHING_ELSE_ESP)
    ) {
      if (this.child.current !== null) {
        this.child.current.closeChatbot();
      }
    }
  };

  waitTimeLimitOverForLiveAgent = (message, diff) => {
    const { englishBot, liveAgentJoined } = this.state;
    if (
      (diff > 899 && !liveAgentJoined && message.message.includes('The estimated wait time is')) ||
      message.message.includes('El tiempo estimado de espera es')
    ) {
      this.setState({ pegaIntent: false });
      this.handleWrapupMessage(
        {
          text: englishBot
            ? "Sorry! It's been more than 10 minutes waiting and all our agents are currently helping other customers. Please, provide your contact details for us to call you back."
            : '¡Lo siento! Han pasado más de 10 minutos esperando y todos nuestros agentes están asistiendo a otros clientes. Por favor, provee tu información de contacto para devolverle la llamada.',
          value: '',
          showYesNo: true,
          msgFromLex: true,
          messageDate: new Date(),
        },
        false
      );
      this.handlerLiveAgentTrigger();
    }
  };

  handleLanguageChange = (chatLanguage) => {
    let englishBotStatus = true;
    if (chatLanguage === ENG) {
      englishBotStatus = true;
    } else {
      englishBotStatus = false;
    }
    const { i18n } = this.props;
    i18n.changeLanguage(chatLanguage);
    setTimeout(() => {
      this.setState({
        englishBot: englishBotStatus,
        defaultTextContent: localStorage.getItem('textContent')
          ? localStorage.getItem('textContent')
          : '',
      });
    }, 1000);
  };

  handlePopupRunningCase = (flag) => {
    const { popupRunning } = this.state;
    this.setState(() => ({ popupRunning: flag }));
    toggleChatbotContainerSize(popupRunning);
  };

  askingForWaitingTime = () => {
    this.setState({ askForTimeAgain: true });
  };

  formInProcessTrigger = (flag) => {
    this.setState({ formInProcess: flag });
  };

  spinnerHandler = (flag) => {
    this.setState({ spinnerTrigger: flag });
  };

  sendAgentWaitingTimeMessageTrigger = (intialWaitTime, diff) => {
    const { messages, englishBot } = this.state;
    const newMessages = [...messages];
    const content = englishBot
      ? `No problem, the estimated wait time is ${timeConvert(intialWaitTime - diff)} minute(s).`
      : `Por supuesto, el tiempo estimado de espera es ${timeConvert(
          intialWaitTime - diff
        )} minuto (s)`;
    const mesg = { message: content, isOwn: false, msgFromLex: true, messageDate: new Date() };
    newMessages.push(mesg);
    this.setState({ messages: newMessages, askForTimeAgain: false });
  };

  ReconnectToAwsConnect = () => {
    this.AWSConnectInit(cusData);
  };

  setReconnectionStatus = () => {
    this.setState({ reConnect: true });
  };

  setSendHiFromApp = () => {
    this.setState({ sendHiFromApp: true });
  };

  AWSConnectInit = async (params) => {
    console.log('in connect call');
    let agentTransferred = false;
    let agentName = '';
    const base64 = btoa(
      `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
    );
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        const initiateChatRequest = {
          ParticipantDetails: {
            DisplayName: 'customerName',
          },
          contactFlowId: '75b9d5a7-da55-4189-9458-d56a3b05e8ff',
          instanceId: '7ad5f796-c1f7-4188-ae12-abbcd859925d',
        };
        axios
          .post('https://j7dmwnxyb2.execute-api.us-east-1.amazonaws.com/Prod', initiateChatRequest)
          .then(async (res) => {
            this.setState({ customerDetails: res.data });
            // cusData = res.data;
            customerChatSession = await connect.ChatSession.create({
              chatDetails: res.data.data.startChatResult,
              options: {
                region: 'us-east-1',
              },
              type: 'CUSTOMER',
            });
            await customerChatSession.connect().then(
              (resp) => {
                const { customerDetails } = this.state;
                disableAllChatButtons();
                this.setState({ liveAgentDisconnected: false });
                let firstMessage = `Hi\n customerName: ${customerDetails.customerName}\n customerEmail: ${customerDetails.customerEmail}\n customerMobile: ${customerDetails.customerMobile}`;
                firstMessage += customerDetails.customerDob
                  ? `\n customerDob: ${customerDetails.customerDob}`
                  : '';
                firstMessage += customerDetails.customerSSN
                  ? `\n customerSSN: ${customerDetails.customerSSN}`
                  : '';
                firstMessage += customerDetails.customerAccountNumber
                  ? `\n customerAccountNumber: ${customerDetails.customerAccountNumber}`
                  : '';
                this.sendToConnect(firstMessage);
                return resp;
              },
              (error) => Promise.reject(error)
            );
            await customerChatSession.onMessage((message) => {
              const { messages, englishBot, sendHiFromApp, btnIntentTrigger } = this.state;
              const { data } = message;
              console.log(data);
              let mesg = {};
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' &&
                data.ParticipantRole === 'CUSTOMER'
              ) {
                this.setState({ connectionSucessful: true });
                // console.log("Joined");
                const alterMessages = [...messages];
                // console.log(alterMessages.length);
                if (
                  alterMessages.length === 0 ||
                  alterMessages[alterMessages.length - 1].message === ''
                ) {
                  // console.log("test here");
                  // alterMessages.splice(-1, 1);
                  // this.setState({messages: alterMessages});
                  // if (sendHiFromApp) {
                  setTimeout(() => {
                    if (btnIntentTrigger !== '') {
                      this.sendToConnect(btnIntentTrigger);
                    } else if (englishBot) {
                      this.sendToConnect(`Hi`);
                    } else {
                      this.sendToConnect(`Hola`);
                    }
                  }, 1000);
                  this.setState({
                    sendHiFromApp: false,
                    connectionSucessful: false,
                    gotGreeting: true,
                  });
                  // }
                }
              }
              let botEndMessage = '';
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded'
              ) {
                agentTransferred = true;
              }
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.participant.left' &&
                data.ParticipantRole === 'AGENT'
              ) {
                agentName = data.DisplayName;
              }
              if (
                data.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' &&
                data.ParticipantRole === 'AGENT'
              ) {
                this.setState({
                  liveAgent: true,
                  liveAgentJoined: true,
                  allowUploadDoc: true,
                  verfiedCustomer: false,
                  awsConnectAgentName: data.DisplayName,
                });
                const oldMessagesObj = [...messages];
                let newMessagesObj = oldMessagesObj;
                newMessagesObj = newMessagesObj.filter(
                  (item) =>
                    !item.message.includes(
                      englishBot ? ALL_AGENTS_BUSY_EST_TIME_ENG : ALL_AGENTS_BUSY_EST_TIME_ESP
                    ) &&
                    !item.message.includes(
                      englishBot ? ESTIMATED_WAIT_TIME_IS : ESTIMATED_WAIT_TIME_IS_ESP
                    ) &&
                    !item.message.includes(
                      englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP
                    )
                );
                if (agentTransferred && agentName) {
                  botEndMessage = englishBot
                    ? `Your chat with ${agentName} has ended!`
                    : `¡Tu chat con ${agentName} ha terminado!`;
                } else {
                  botEndMessage = englishBot ? CHAT_WITH_BOT_ENDED_ENG : CHAT_WITH_BOT_ENDED_ESP;
                }
                const chatEndedMessage = {
                  message: botEndMessage,
                  isOwn: false,
                  msgFromLex: true,
                  messageDate: new Date(),
                };
                newMessagesObj.push(chatEndedMessage);
                const agentEnterMessage = englishBot
                  ? `${data.DisplayName} has entered the chat`
                  : `${data.DisplayName} se ha unido al chat`;
                const chatAgentEnterMessage = {
                  message: agentEnterMessage,
                  isOwn: false,
                  agentEnterMessage: true,
                  messageDate: new Date(),
                };
                newMessagesObj.push(chatAgentEnterMessage);
                setTimeout(() => {
                  this.setState({
                    messages: newMessagesObj,
                    liveAgent: true,
                    waitingForLiveAgent: false,
                  });
                }, 1000);
              }
              if (data.Type !== 'EVENT') {
                if (
                  data.Content &&
                  (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
                ) {
                  if (data.ParticipantRole === 'AGENT') {
                    this.setState({
                      agentTypingFlag: false,
                      allowUploadDoc: true,
                      awsConnectAgentName: data.DisplayName,
                      liveAgent: true,
                    });
                    mesg = {
                      message: data.Content,
                      isOwn: false,
                      msgFromLex: false,
                      messageDate: new Date(),
                    };
                  } else if (data.Content.includes('templateType')) {
                    this.lexSuccessHandler(data);
                  } else {
                    mesg = {
                      message: data.Content,
                      isOwn: false,
                      msgFromLex: true,
                      messageDate: new Date(),
                    };
                  }
                  this.setState({ waitingForLiveAgent: false });
                  const oldMessages = [...messages];
                  if (
                    oldMessages.length > 0 &&
                    oldMessages[oldMessages.length - 1].message === ''
                  ) {
                    oldMessages.splice(-1, 1);
                  }
                  const newMessages = oldMessages;
                  if (
                    (mesg.message.includes(ALL_AGENTS_BUSY_EST_TIME_ENG) ||
                      mesg.message.includes(ALL_AGENTS_BUSY_EST_TIME_ESP)) &&
                    newMessages.findIndex((item) => item.message === botEndMessage) === -1
                  ) {
                    const num = mesg.message.match(/-?\d+\.?\d*/);
                    intialWaitingTime = parseInt(num[0], 10);
                    if (parseInt(num[0], 10) < 5) {
                      mesg.message = mesg.message.replace(num, timeConvert(parseInt(num[0], 10)));
                    } else {
                      mesg.message = mesg.message.replace(
                        num,
                        englishBot ? MORE_THAN_FIVE_ENG : MORE_THAN_FIVE_ESP
                      );
                    }
                  }
                  if (
                    mesg.message.includes('is not available right now') === false &&
                    mesg.message.includes('no está disponible en este momento') === false &&
                    mesg.message.includes('All Agents are not available right now') === false &&
                    mesg.message.includes('Todos los agentes están ocupados en este momento') ===
                      false &&
                    mesg.message.includes('Transferring now') === false &&
                    mesg.message.includes('Transfiriendo') === false &&
                    mesg.message.includes('Now transferring') === false &&
                    mesg.message.includes('ahora transfiriendo') === false
                  ) {
                    if (data.Content !== 'IgnoreMessage') {
                      newMessages.push(mesg);
                      setTimeout(() => {
                        this.setState({ messages: newMessages });
                      }, 1000);
                    }
                  }
                }
                if (
                  data.Attachments &&
                  data.Attachments.length > 0 &&
                  (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
                ) {
                  const messagesArr = [...messages];
                  const item = messagesArr[messagesArr.length - 1];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - 1] = item;
                  this.setState({ messages: messagesArr });
                  const oldMessages = [...messages];
                  if (
                    oldMessages.length > 0 &&
                    oldMessages[oldMessages.length - 1].message === ''
                  ) {
                    oldMessages.splice(-1, 1);
                  }
                  const recievedFilesMessage = {
                    message: englishBot
                      ? `${data.DisplayName} sent you following attachment:`
                      : `${data.DisplayName} te envió el siguiente documento:`,
                    isOwn: false,
                    msgFromLex: false,
                    messageDate: new Date(),
                  };
                  const newMessages = oldMessages;
                  newMessages.push(recievedFilesMessage);
                  data.Attachments.map(async (Attachment) => {
                    const file = await downloadAttachmentFile(
                      Attachment.AttachmentId,
                      customerChatSession.controller.connectionHelper.connectionDetailsProvider
                        .connectionToken
                    );
                    const mesgItem = {
                      message: Attachment.AttachmentName,
                      messageLink: file,
                      isOwn: false,
                      msgFromLex: false,
                      attachmentFile: true,
                      AttachmentId: Attachment.AttachmentId,
                      messageDate: new Date(),
                    };
                    newMessages.push(mesgItem);
                    this.setState({ messages: newMessages });
                  });
                }
              }
            });

            await customerChatSession.onTyping((typingEvent) => {
              const { messages } = this.state;
              const { data } = typingEvent;
              if (data.ParticipantRole === 'AGENT') {
                this.setState({ agentTypingFlag: true, hideOnCustomerType: false });
                const messagesArr = [...messages];
                for (let i = 1; i < 10; i++) {
                  const item = messagesArr[messagesArr.length - i];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - i] = item;
                  this.setState({ messages: messagesArr });
                }
                const inputText = localStorage.getItem('textContent');
                if (inputText !== null && inputText !== '') {
                  this.sendTypingEventToConnect();
                }
              }
            });

            await customerChatSession.onConnectionBroken((data) => {
              const { messages } = this.state;
              const messagesArr = [...messages];
              const item = messagesArr[messagesArr.length - 1];
              item.sentFailedStatus = true;
              messagesArr[messagesArr.length - 1] = item;
              this.setState({ messages: messagesArr });
            });
            await customerChatSession.onEnded(() => {
              const { messages, englishBot, allowUploadDoc } = this.state;
              const messagesArray = [...messages];
              const message = englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP;
              if (
                messagesArray[messagesArray.length - 1].message !== message &&
                messagesArray[messagesArray.length - 1].message !== SORRY_AGENTS_ARE_BUSY_ENG &&
                messagesArray[messagesArray.length - 1].message !== SORRY_AGENTS_BUSY_ESP
              ) {
                this.setState(
                  {
                    liveAgent: false,
                    connectionSucessful: false,
                    liveAgentJoined: false,
                    verfiedCustomer: false,
                    agentTypingFlag: false,
                    waitingForLiveAgent: false,
                    pegaIntent: false,
                    liveAgentDisconnected: true,
                    customerDetails: {},
                  },
                  () => {
                    if (allowUploadDoc) {
                      const oldMesg = [...messages];
                      messages.push({
                        message,
                        messageDate: new Date(),
                        isOwn: false,
                        msgFromLex: true,
                      });
                      this.setState({ messages: oldMesg });
                    }
                  }
                );
                const messagesArr = [...messages];
                for (let i = 1; i < 10; i++) {
                  const item = messagesArr[messagesArr.length - i];
                  item.readStatus = true;
                  messagesArr[messagesArr.length - i] = item;
                  this.setState({ messages: messagesArr });
                }
              }
              this.setState({ allowUploadDoc: false });
            });
          })
          .catch((err) => {
            const { messages, englishBot } = this.state;
            const content = englishBot ? SOMETHING_WENT_WRONG_ENG : SOMETHING_WENT_WRONG_ESP;
            const messagesArr = [...messages];
            const mesg = {
              message: content,
              isOwn: false,
              msgFromLex: true,
              messageDate: new Date(),
            };
            if (messagesArr[messagesArr.length - 1]?.message === '') {
              messagesArr.splice(-1, 1);
            }
            messagesArr.push(mesg);
            this.setState({
              messages: messagesArr,
              waitingForLiveAgent: false,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // AWSConnectInit = async (params) => {
  //   let agentTransferred = false;
  //   let agentName = '';
  //   axios
  //     .post(
  //       process.env.REACT_APP_TOKEN_SERVICE_URL,
  //       {
  //         clientId: process.env.REACT_APP_CLIENT_ID,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Basic ${base64Authorization}`,
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       axios
  //         .post(process.env.REACT_APP_CONNECT_AUTH_URL, params, {
  //           headers: {
  //             Authorization: `Bearer ${response.data.access_token}`,
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //           },
  //         })
  //         .then(async (res) => {
  //           cusData = res.data;
  //           customerChatSession = await connect.ChatSession.create({
  //             chatDetails: res.data,
  //             options: {
  //               region: 'us-east-1',
  //             },
  //             type: 'CUSTOMER',
  //           });
  //           await customerChatSession.connect().then(
  //             (resp) => {
  //               const { customerDetails } = this.state;
  //               disableAllChatButtons();
  //               this.setState({ liveAgent: true, liveAgentDisconnected: false });
  //               let firstMessage = `Hi\n customerName: ${customerDetails.customerName}\n customerEmail: ${customerDetails.customerEmail}\n customerMobile: ${customerDetails.customerMobile}`;
  //               firstMessage += customerDetails.customerDob
  //                 ? `\n customerDob: ${customerDetails.customerDob}`
  //                 : '';
  //               firstMessage += customerDetails.customerSSN
  //                 ? `\n customerSSN: ${customerDetails.customerSSN}`
  //                 : '';
  //               firstMessage += customerDetails.customerAccountNumber
  //                 ? `\n customerAccountNumber: ${customerDetails.customerAccountNumber}`
  //                 : '';
  //               this.sendToConnect(firstMessage);
  //               return resp;
  //             },
  //             (error) => Promise.reject(error)
  //           );
  //           await customerChatSession.onMessage((message) => {
  //             const { messages, englishBot } = this.state;
  //             const { data } = message;
  //             let mesg = {};
  //             let botEndMessage = '';
  //             if (
  //               data.ContentType === 'application/vnd.amazonaws.connect.event.transfer.succeeded'
  //             ) {
  //               agentTransferred = true;
  //             }
  //             if (
  //               data.ContentType === 'application/vnd.amazonaws.connect.event.participant.left' &&
  //               data.ParticipantRole === 'AGENT'
  //             ) {
  //               agentName = data.DisplayName;
  //             }
  //             if (
  //               data.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined' &&
  //               data.ParticipantRole === 'AGENT'
  //             ) {
  //               this.setState({
  //                 liveAgentJoined: true,
  //                 allowUploadDoc: true,
  //                 verfiedCustomer: false,
  //                 awsConnectAgentName: data.DisplayName,
  //               });
  //               const oldMessagesObj = [...messages];
  //               let newMessagesObj = oldMessagesObj;
  //               newMessagesObj = newMessagesObj.filter(
  //                 (item) =>
  //                   !item.message.includes(
  //                     englishBot
  //                       ? 'All our agents are busy now. The estimated wait time is'
  //                       : 'Todos nuestros agentes están ocupados. El tiempo estimado de espera es'
  //                   ) &&
  //                   !item.message.includes(
  //                     englishBot
  //                       ? 'No problem, the estimated wait time is'
  //                       : 'Por supuesto, el tiempo estimado de espera es'
  //                   ) &&
  //                   !item.message.includes(
  //                     englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP
  //                   )
  //               );
  //               if (agentTransferred && agentName) {
  //                 botEndMessage = englishBot
  //                   ? `Your chat with ${agentName} has ended!`
  //                   : `¡Tu chat con ${agentName} ha terminado!`;
  //               } else {
  //                 botEndMessage = englishBot
  //                   ? CHAT_WITH_BOT_ENDED_ENG
  //                   : CHAT_WITH_BOT_ENDED_ESP;
  //               }
  //               const chatEndedMessage = {
  //                 message: botEndMessage,
  //                 isOwn: false,
  //                 msgFromLex: true,
  //                 messageDate: new Date(),
  //               };
  //               newMessagesObj.push(chatEndedMessage);
  //               const agentEnterMessage = englishBot
  //                 ? `${data.DisplayName} has entered the chat`
  //                 : `${data.DisplayName} se ha unido al chat`;
  //               const chatAgentEnterMessage = {
  //                 message: agentEnterMessage,
  //                 isOwn: false,
  //                 agentEnterMessage: true,
  //                 messageDate: new Date(),
  //               };
  //               newMessagesObj.push(chatAgentEnterMessage);
  //               setTimeout(() => {
  //                 this.setState({
  //                   messages: newMessagesObj,
  //                   liveAgent: true,
  //                   waitingForLiveAgent: false,
  //                 });
  //               }, 1000);
  //             }
  //             if (data.Type !== 'EVENT') {
  //               if (
  //                 data.Content &&
  //                 (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
  //               ) {
  //                 if (data.ParticipantRole === 'AGENT') {
  //                   this.setState({
  //                     agentTypingFlag: false,
  //                     allowUploadDoc: true,
  //                     awsConnectAgentName: data.DisplayName,
  //                     liveAgent: true,
  //                   });
  //                   mesg = {
  //                     message: data.Content,
  //                     isOwn: false,
  //                     msgFromLex: false,
  //                     messageDate: new Date(),
  //                   };
  //                 } else {
  //                   mesg = {
  //                     message: data.Content,
  //                     isOwn: false,
  //                     msgFromLex: true,
  //                     messageDate: new Date(),
  //                   };
  //                 }
  //                 this.setState({ waitingForLiveAgent: false });
  //                 const oldMessages = [...messages];
  //                 if (
  //                   oldMessages.length > 0 &&
  //                   oldMessages[oldMessages.length - 1].message === ''
  //                 ) {
  //                   oldMessages.splice(-1, 1);
  //                 }
  //                 const newMessages = oldMessages;
  //                 if (
  //                   (mesg.message.includes(
  //                     'All our agents are busy now. The estimated wait time is'
  //                   ) ||
  //                     mesg.message.includes(
  //                       'Todos nuestros agentes están ocupados. El tiempo estimado de espera es'
  //                     )) &&
  //                   newMessages.findIndex((item) => item.message === botEndMessage) === -1
  //                 ) {
  //                   const num = mesg.message.match(/-?\d+\.?\d*/);
  //                   intialWaitingTime = parseInt(num[0], 10);
  //                   if (parseInt(num[0], 10) < 5) {
  //                     mesg.message = mesg.message.replace(num, timeConvert(parseInt(num[0], 10)));
  //                   } else {
  //                     mesg.message = mesg.message.replace(
  //                       num,
  //                       englishBot ? 'more than 5' : 'más de 5'
  //                     );
  //                   }
  //                 }
  //                 if (
  //                   mesg.message.includes('is not available right now') === false &&
  //                   mesg.message.includes('no está disponible en este momento') === false &&
  //                   mesg.message.includes('All Agents are not available right now') === false &&
  //                   mesg.message.includes('Todos los agentes están ocupados en este momento') ===
  //                     false &&
  //                   mesg.message.includes('Transferring now') === false &&
  //                   mesg.message.includes('Transfiriendo') === false &&
  //                   mesg.message.includes('Now transferring') === false &&
  //                   mesg.message.includes('ahora transfiriendo') === false
  //                 ) {
  //                   newMessages.push(mesg);
  //                   setTimeout(() => {
  //                     this.setState({ messages: newMessages });
  //                   }, 1000);
  //                 }
  //               }
  //               if (
  //                 data.Attachments &&
  //                 data.Attachments.length > 0 &&
  //                 (data.ParticipantRole === 'SYSTEM' || data.ParticipantRole === 'AGENT')
  //               ) {
  //                 const messagesArr = [...messages];
  //                 const item = messagesArr[messagesArr.length - 1];
  //                 item.readStatus = true;
  //                 messagesArr[messagesArr.length - 1] = item;
  //                 this.setState({ messages: messagesArr });
  //                 const oldMessages = [...messages];
  //                 if (
  //                   oldMessages.length > 0 &&
  //                   oldMessages[oldMessages.length - 1].message === ''
  //                 ) {
  //                   oldMessages.splice(-1, 1);
  //                 }
  //                 const recievedFilesMessage = {
  //                   message: englishBot
  //                     ? `${data.DisplayName} sent you following attachment:`
  //                     : `${data.DisplayName} te envió el siguiente documento:`,
  //                   isOwn: false,
  //                   msgFromLex: false,
  //                   messageDate: new Date(),
  //                 };
  //                 const newMessages = oldMessages;
  //                 newMessages.push(recievedFilesMessage);
  //                 data.Attachments.map(async (Attachment) => {
  //                   const file = await downloadAttachmentFile(
  //                     Attachment.AttachmentId,
  //                     customerChatSession.controller.connectionHelper.connectionDetailsProvider
  //                       .connectionToken
  //                   );
  //                   const mesgItem = {
  //                     message: Attachment.AttachmentName,
  //                     messageLink: file,
  //                     isOwn: false,
  //                     msgFromLex: false,
  //                     attachmentFile: true,
  //                     AttachmentId: Attachment.AttachmentId,
  //                     messageDate: new Date(),
  //                   };
  //                   newMessages.push(mesgItem);
  //                   this.setState({ messages: newMessages });
  //                 });
  //               }
  //             }
  //           });

  //           await customerChatSession.onTyping((typingEvent) => {
  //             const { messages } = this.state;
  //             const { data } = typingEvent;
  //             if (data.ParticipantRole === 'AGENT') {
  //               this.setState({ agentTypingFlag: true, hideOnCustomerType: false });
  //               const messagesArr = [...messages];
  //               for (let i = 1; i < 10; i++) {
  //                 const item = messagesArr[messagesArr.length - i];
  //                 item.readStatus = true;
  //                 messagesArr[messagesArr.length - i] = item;
  //                 this.setState({ messages: messagesArr });
  //               }
  //               const inputText = localStorage.getItem('textContent');
  //               if (inputText !== null && inputText !== '') {
  //                 this.sendTypingEventToConnect();
  //               }
  //             }
  //           });

  //           await customerChatSession.onConnectionBroken((data) => {
  //             const { messages } = this.state;
  //             const messagesArr = [...messages];
  //             const item = messagesArr[messagesArr.length - 1];
  //             item.sentFailedStatus = true;
  //             messagesArr[messagesArr.length - 1] = item;
  //             this.setState({ messages: messagesArr });
  //           });
  //           await customerChatSession.onEnded(() => {
  //             const { messages, englishBot, allowUploadDoc } = this.state;
  //             const messagesArray = [...messages];
  //             const message = englishBot
  //               ? LIVE_AGENT_CHAT_ENDED_ENG
  //               : LIVE_AGENT_CHAT_ENDED_ESP;
  //             if (
  //               messagesArray[messagesArray.length - 1].message !== message &&
  //               messagesArray[messagesArray.length - 1].message !==
  //                 SORRY_AGENTS_ARE_BUSY_ENG &&
  //               messagesArray[messagesArray.length - 1].message !==
  //                 SORRY_AGENTS_BUSY_ESP
  //             ) {
  //               this.setState(
  //                 {
  //                   liveAgent: false,
  //                   connectionSucessful: false,
  //                   liveAgentJoined: false,
  //                   verfiedCustomer: false,
  //                   agentTypingFlag: false,
  //                   waitingForLiveAgent: false,
  //                   pegaIntent: false,
  //                   liveAgentDisconnected: true,
  //                   customerDetails: {},
  //                 },
  //                 () => {
  //                   if (allowUploadDoc) {
  //                     const oldMesg = [...messages];
  //                     messages.push({
  //                       message,
  //                       messageDate: new Date(),
  //                       isOwn: false,
  //                       msgFromLex: true,
  //                     });
  //                     this.setState({ messages: oldMesg });
  //                   }
  //                 }
  //               );
  //               const messagesArr = [...messages];
  //               for (let i = 1; i < 10; i++) {
  //                 const item = messagesArr[messagesArr.length - i];
  //                 item.readStatus = true;
  //                 messagesArr[messagesArr.length - i] = item;
  //                 this.setState({ messages: messagesArr });
  //               }
  //             }
  //             this.setState({ allowUploadDoc: false });
  //           });
  //         })
  //         .catch((err) => {
  //           const { messages, englishBot } = this.state;
  //           const content = englishBot
  //             ? SOMETHING_WENT_WRONG_ENG
  //             : SOMETHING_WENT_WRONG_ESP;
  //           const messagesArr = [...messages];
  //           const mesg = {
  //             message: content,
  //             isOwn: false,
  //             msgFromLex: true,
  //             messageDate: new Date(),
  //           };
  //           if (messagesArr[messagesArr.length - 1]?.message === '') {
  //             messagesArr.splice(-1, 1);
  //           }
  //           messagesArr.push(mesg);
  //           this.setState({
  //             messages: messagesArr,
  //             waitingForLiveAgent: false,
  //           });
  //         });
  //     })
  //     .catch();
  // };

  handleNewUserMessage = (newMessage, ownMessage) => {
    const {
      messages,
      liveAgent,
      englishBot,
      liveAgentDisconnected,
      agentTypingFlag,
      sspSiteFlag,
      btnIntentTrigger,
    } = this.state;
    const message = englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP;
    if (messages.length === 0) {
      // if(!(newMessage.text === 'PEGA_CONNECT'))
      if (sspSiteFlag === 'true') {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: true,
              msgForAgent: false,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
            {
              message: '',
              isOwn: ownMessage,
              showYesNo: false,
            },
          ],
        });
      } else {
        this.setState({
          messages: [
            ...messages,
            {
              message: '',
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              messageDate: new Date(),
              showYesNo: false,
            },
          ],
        });
      }
    } else if (newMessage.value === message) {
      if (!liveAgentDisconnected) {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
          ],
        });
      }
    } else if (newMessage.text !== 'PEGA_CONNECT') {
      liveAgent
        ? this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: !!agentTypingFlag,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
            ],
          })
        : this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: false,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
              {
                message: '',
                isOwn: ownMessage,
                showYesNo: false,
              },
            ],
          });
    } else {
      this.setState({
        messages: [
          ...messages,
          {
            message: '',
            isOwn: ownMessage,
            msgForAgent: liveAgent,
            readStatus: false,
            sentFailedStatus: false,
            showYesNo: false,
            messageDate: new Date(),
          },
        ],
      });
    }
    this.setState({ isServiceCase: false });
    if (
      newMessage.type ||
      newMessage.value === 'search_by_ssn_again' ||
      newMessage.value.includes('*LoadMore')
    ) {
      this.setState({ isServiceCase: true });
    }
    // if (liveAgent) {
    //   this.sendToConnect(newMessage.value);
    // } else if (newMessage.value !== message) {
    //   setTimeout(() => {
    //     this.sendToLex(newMessage.value);
    //   }, 200);
    // }
    if (newMessage.value !== '') {
      this.sendToConnect(newMessage.value);
    }
  };

  handleWrapupMessage = (newMessage, ownMessage) => {
    const { messages, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage.showYesNo,
          showWrapupStepTwo: newMessage.showWrapupStepTwo,
          messageDate: newMessage.messageDate,
        },
      ],
    });
  };

  handeLanguageChangeMessage = (newMessage, ownMessage) => {
    const { messages, englishBot, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          chatLanguage: englishBot ? ENG : ESP,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: true,
          sentFailedStatus: false,
          languageChangeYesNo: newMessage.languageChangeYesNo,
        },
      ],
    });
  };

  handeButtonLinkMessage = (newMessage, ownMessage) => {
    const { messages, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgFromLex: !liveAgentJoined,
          readStatus: true,
          sentFailedStatus: false,
          isButtonMessage: newMessage.isButtonMessage,
        },
      ],
    });
  };

  handleButtonClickDuringAgentCallMessage = (newMessage, ownMessage) => {
    const { messages, liveAgent, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage?.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: !liveAgentJoined,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage?.showYesNo,
          showWrapupStepTwo: newMessage?.showWrapupStepTwo,
          messageDate: newMessage?.messageDate,
        },
      ],
    });
  };

  handleDateMessage = (newMessage, ownMessage) => {
    const { messages } = this.state;
    this.dateReceivedHandler();
    this.setState(
      {
        messages: [
          ...messages,
          {
            message: newMessage.text,
            isOwn: ownMessage,
            msgForAgent: false,
            readStatus: false,
            sentFailedStatus: false,
            showStartDateCalendar: newMessage.showStartDateCalendar,
            showEndDateCalendar: newMessage.showEndDateCalendar,
          },
        ],
      },
      () => {
        if (!newMessage.showEndDateCalendar) {
          this.spinnerHandler(true);
          this.sendToConnect(newMessage.value);
        }
      }
    );
  };

  dateReceivedHandler = () => {
    const { messages } = this.state;
    const messagesArr = [...messages];
    const item = messagesArr[messagesArr.length - 1];
    if (item) {
      item.dateReceived = true;
      messagesArr[messagesArr.length - 1] = item;
      this.setState({ messages: messagesArr });
    }
  };

  setUserEmailFromIntent = (email) => {
    this.setState({ userEmail: email });
  };

  setVerifedCustomer = (flag) => {
    this.setState({ verfiedCustomer: flag });
  };

  handlerPageIntent = () => {
    this.setState({ pegaIntent: false });
  };

  handlerIntentTrigger = () => {
    this.setState({ btnIntentTrigger: '' });
  };

  setCustomerDetails = (data) => {
    this.setState({ customerDetails: data });
  };

  handlerLiveAgentTrigger = () => {
    this.setState(
      {
        liveAgent: false,
        connectionSucessful: false,
        liveAgentJoined: false,
        liveAgentDisconnected: true,
        waitingForLiveAgent: false,
        customerDetails: {},
      },
      () => {
        if (customerChatSession.controller) {
          customerChatSession.disconnectParticipant();
        }
      }
    );
  };

  sendToLex = (message) => {
    const { slotToElicit, englishBot, sessionID, chatDeviceType, chatDeviceOS, isServiceCase } =
      this.state;
    const params = getParameters(
      message,
      slotToElicit,
      englishBot,
      sessionID,
      chatDeviceType,
      chatDeviceOS
    );
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        axios
          .post(`${process.env.REACT_APP_POST_CALL_API}`, params, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            const { data } = res;
            this.lexSuccessHandler(data);
          })
          .catch((err) => {
            const { messages } = this.state;
            const oldMessages = [...messages];
            if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
              oldMessages.splice(-1, 1);
            }
            const newMessages = oldMessages;
            setTimeout(() => {
              this.setState({ messages: newMessages }, () => {
                this.lexFailedHandler(err);
              });
            }, 100);
          });
      })
      .catch();
  };

  lexSuccessHandler = (data) => {
    const { messages, isServiceCase } = this.state;
    if (data) {
      this.spinnerHandler(false);
      let mesg = {};
      // if (data.sessionState.sessionAttributes.validDebitCard === 'true') {
      //   this.setState({ cardValidFlag: true });
      // } else {
      //   this.setState({ cardValidFlag: false });
      // }
      if (data.Content.includes('templateType')) {
        const listPickerData = JSON.parse(data.Content);
        console.log('listPickerData', listPickerData);
        this.setState({ sessionID: listPickerData.data.content.sessionId });
        if (listPickerData.data.content.intentName !== null) {
          if (
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent' ||
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent_esp'
          ) {
            this.setState({ pegaIntent: true });
          } else if (
            intentArrWithPegaTrue.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ pegaIntent: true });
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else if (
            intentArrWithPegaFalse.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else {
            this.setState({ pegaIntent: false });
            this.setState({ slotToElicit: '' });
          }
        }
        if (listPickerData.data.content.title.includes('Your request is processing.')) {
          this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
        } else if (
          listPickerData.data.content.title !== 'Thank you!' &&
          listPickerData.data.content.title !== '¡Gracias!'
        ) {
          mesg = {
            message: listPickerData.data.content.title,
            msgFromLex: true,
            isOwn: false,
            intentName: listPickerData.data.content.intentName,
            slotToElicit: listPickerData.data.content.slotToElicit,
            messageDate: new Date(),
          };
          if (listPickerData.data.content.elements[0].title !== ' ') {
            mesg.responseCard = listPickerData.data.content.elements;
            mesg.responseCard.isServiceCase = isServiceCase;
          }
        }
      } else {
        mesg = {
          message: data.Content,
          isOwn: true,
          msgFromLex: true,
          messageDate: new Date(),
        };
      }
      const oldMessages = [...messages];
      if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
        oldMessages.splice(-1, 1);
      }
      const newMessages = oldMessages;
      if (Object.keys(mesg).length !== 0) {
        newMessages.push(mesg);
      }
      setTimeout(() => {
        this.setState({ messages: newMessages }, () => {
          focusInChatbot();
        });
      }, 100);
    }
  };

  lexFailedHandler = (err) => {
    const { englishBot, messages } = this.state;
    if (err.message === 'Request failed with status code 504') {
      this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
      // this.handeButtonLinkMessage({ text: 'Inprogress', value: '', isButtonMessage: true }, true);
      return;
    }
    if (err && err.message !== 'Request failed with status code 504') {
      const botMessage = englishBot ? TRY_AGAIN_LATER_ENG : TRY_AGAIN_LATER_ESP;
      const mesg = {
        message: botMessage,
        msgFromLex: true,
        isOwn: false,
        messageDate: new Date(),
      };
      const buttonText = englishBot ? CONTACT_US_ENG : CONTACT_US_ESP;
      const buttonValue = englishBot
        ? 'https://orientalbank.com/en/contactus/'
        : 'https://orientalbank.com/es/contactanos/';
      mesg.responseCard = [{ title: buttonText, subtitle: buttonValue }];
      const newMessages = [...messages];
      if (
        newMessages[newMessages.length - 1] &&
        newMessages[newMessages.length - 1].message === ''
      ) {
        newMessages.splice(-1, 1);
      }
      newMessages.push(mesg);
      this.setState({ messages: newMessages, lexError: true }, () => {
        focusInChatbot();
      });
    } else {
      this.setState({ lexError: false });
    }
  };

  deleteSessionToLex = () => {
    const { englishBot, sessionID } = this.state;
    deleteSessioApiCall(englishBot, sessionID);
    this.setState({ sessionID: '', spinnerTrigger: false });
  };

  sendDocToConnect = (docAttachment) => {
    if (customerChatSession) {
      customerChatSession.sendAttachment({
        attachment: docAttachment,
      });
    }
  };

  sendTypingEventToConnect = () => {
    const { agentTypingFlag, liveAgentJoined } = this.state;
    if (agentTypingFlag === false) {
      this.setState({ hideOnCustomerType: true });
    }
    if (customerChatSession && liveAgentJoined) {
      customerChatSession.sendEvent({
        contentType: 'application/vnd.amazonaws.connect.event.typing',
      });
    }
  };

  sendToConnect = async (message) => {
    this.setState({ hideOnCustomerType: false });
    if (customerChatSession) {
      await customerChatSession.controller.sendMessage({
        message,
        contentType: 'text/plain',
        options: {
          // optional
          waitInQ: message,
        },
      });
    }
  };

  addMessages = (data) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, { message: data, isOwn: true }, { message: '', isOwn: false }],
    });
    this.sendToConnect(data);
  };

  clearMessage = () => {
    const { originalBotLanguage } = this.state;
    this.setState({
      englishBot: originalBotLanguage,
      messages: [],
      pegaIntent: false,
      slotToElicit: '',
      btnIntentTrigger: '',
    });
  };

  render() {
    const {
      sessionID,
      reConnect,
      gotGreeting,
      connectionSucessful,
      btnIntentTrigger,
      verfiedCustomer,
      userEmail,
      hideOnCustomerType,
      liveAgentJoined,
      agentTypingFlag,
      customerDetails,
      awsConnectAgentName,
      allowUploadDoc,
      liveAgent,
      waitingForLiveAgent,
      pegaIntent,
      slotToElicit,
      defaultTextContent,
      englishBot,
      lexError,
      messages,
      spinnerTrigger,
      cardValidFlag,
      sspSiteFlag,
    } = this.state;
    return (
      <ThemeProvider theme={this.theme}>
        <div role="main">
          <FixedWrapper.Root>
            <FixedWrapper.Maximized
              className={`main-wrapper ${deviceOS === 'IOS' ? 'device-iphone' : ''}`}
            >
              <Maximized
                ref={this.child}
                sessionID={sessionID}
                cardValidFlag={cardValidFlag}
                sendToLex={this.sendToLex}
                setReconnectionStatus={this.setReconnectionStatus}
                reConnect={reConnect}
                gotGreeting={gotGreeting}
                spinnerTrigger={spinnerTrigger}
                connectionSucessful={connectionSucessful}
                btnIntentTrigger={btnIntentTrigger}
                verfiedCustomer={verfiedCustomer}
                setVerifedCustomer={this.setVerifedCustomer}
                userEmail={userEmail}
                setUserEmailFromIntent={this.setUserEmailFromIntent}
                hideOnCustomerType={hideOnCustomerType}
                sendTypingEventToConnect={this.sendTypingEventToConnect}
                liveAgentJoined={liveAgentJoined}
                askingForWaitingTime={this.askingForWaitingTime}
                handleButtonClickDuringAgentCallMessage={
                  this.handleButtonClickDuringAgentCallMessage
                }
                setSendHiFromApp={this.setSendHiFromApp}
                agentTypingFlag={agentTypingFlag}
                customerDetails={customerDetails}
                awsConnectAgentName={awsConnectAgentName}
                allowUploadDoc={allowUploadDoc}
                runningAWSConnect={liveAgent}
                waitingForLiveAgent={waitingForLiveAgent}
                sendDocToConnect={this.sendDocToConnect}
                ReconnectToAwsConnect={this.ReconnectToAwsConnect}
                handlerLiveAgentTrigger={this.handlerLiveAgentTrigger}
                pegaIntent={pegaIntent}
                slotToElicit={slotToElicit}
                handlerPageIntent={this.handlerPageIntent}
                handlerIntentTrigger={this.handlerIntentTrigger}
                defaultTextareaContent={defaultTextContent}
                englishBot={englishBot}
                lexError={lexError}
                messages={messages}
                sspSiteFlag={sspSiteFlag}
                deleteSessionToLex={this.deleteSessionToLex}
                handleLanguageChange={this.handleLanguageChange}
                handleMessage={this.handleNewUserMessage}
                handleWrapupMessage={this.handleWrapupMessage}
                handlePopupRunningCase={this.handlePopupRunningCase}
                handeLanguageChangeMessage={this.handeLanguageChangeMessage}
                handeButtonLinkMessage={this.handeButtonLinkMessage}
                handleDateMessage={this.handleDateMessage}
                addMessages={this.addMessages}
                onCloseClick={this.clearMessage}
                formInProcessTrigger={this.formInProcessTrigger}
                setCustomerDetails={this.setCustomerDetails}
                spinnerHandler={this.spinnerHandler}
                sendToConnect={this.sendToConnect}
                {...this.props}
              />
            </FixedWrapper.Maximized>
            <FixedWrapper.Minimized className="main-wrapper-minimize">
              <Minimized {...this.props} btnIntentTrigger={btnIntentTrigger} />
            </FixedWrapper.Minimized>
          </FixedWrapper.Root>
        </div>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(App);

App.propTypes = {
  i18n: PropTypes.object,
};
