import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createTheme, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { CommonStyles } from '../balance-cancellation/CommonStyles';
import StepperWizard from '../balance-cancellation/Wizard/StepperWizard';
import MainHeader from './Header/TopHeader';
import Footer from './Footer/Footer';
import OrientalImage from '../images/oriental-bank.png';
import { ENG, ESP } from '../../../Constants';

const useCommonStyles = CommonStyles();
const SimpleDialog = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    englishBot,
    modalHandler,
    webFormType,
    setFormType,
    handleModalLanguageChange,
    sessionID,
  } = props;
  const CommonCssStyles = useCommonStyles();
  const [showLanguageLink, setShowLanguageLink] = useState(true);
  const handleClose = () => {
    onClose(selectedValue);
    setFormType();
    modalHandler();
    setShowLanguageLink(true);
  };
  const languageLinkHandler = () => {
    setShowLanguageLink(false);
  };
  return (
    <ThemeProvider theme={DATEPICKERTHEME}>
      <Dialog
        onClose={handleClose}
        role="dialog"
        disableBackdropClick
        aria-labelledby="dialog1Title dialog2Title"
        aria-describedby="dialog1Desc"
        data-testid="dialogModal"
        open={open}
        className="modal_container"
      >
        <MainHeader
          englishBot={englishBot}
          languageFlag={showLanguageLink}
          handleModalLanguageChange={handleModalLanguageChange}
          handleClose={handleClose}
        />
        <div className={CommonCssStyles.main_modal_container}>
          <Grid item container className={CommonCssStyles.main_header}>
            <img
              src={OrientalImage}
              className={CommonCssStyles.oriental}
              alt="Oriental Logo"
              aria-label="Oriental Logo"
            />
          </Grid>
          <StepperWizard
            englishBot={englishBot}
            languageLinkHandler={languageLinkHandler}
            sessionID={sessionID}
            onClose={handleClose}
            webFormType={webFormType}
          />
        </div>
        <Footer />
      </Dialog>
    </ThemeProvider>
  );
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sessionID: PropTypes.string.isRequired,
  englishBot: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
  modalHandler: PropTypes.func,
  webFormType: PropTypes.string,
  setFormType: PropTypes.func,
  handleModalLanguageChange: PropTypes.func,
};

const SimpleDialogDemo = (props) => {
  const { englishBot, modalHandler, FormType, setFormType, sessionID, i18n } = props;
  const [open, setOpen] = React.useState(true);
  const [modalLanguage, setModalLanguage] = React.useState(englishBot);

  const handleModalLanguageChange = (chatLanguage) => {
    setModalLanguage(chatLanguage === ENG);
    i18n.changeLanguage(chatLanguage);
  };
  useEffect(() => {
    i18n.changeLanguage(englishBot === true ? ENG : ESP);
  }, []);
  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        data-testid="inactivityModal_id"
        sessionID={sessionID}
        englishBot={modalLanguage}
        handleModalLanguageChange={handleModalLanguageChange}
        modalHandler={modalHandler}
        webFormType={FormType}
        setFormType={setFormType}
      />
    </div>
  );
};

const DATEPICKERTHEME = createTheme({
  overrides: {
    // MuiDialog: {
    //     paperWidthSm: {
    //       maxWidth: "95%",
    //       maxHeight: "95%",
    //       width: "95%",
    //       height: "95%",
    //       minHeight: "95vh",
    //       '@media (max-width: 500px)': {
    //         maxWidth: "98%",
    //         maxHeight: "98%",
    //         width: "98%",
    //         height: "98%",
    //         minHeight: '95vh',
    //         minWidth: "95vw",
    //       }
    //     }
    // },
    MuiDialogTitle: {
      root: {
        padding: '16px 12px',
      },
    },
  },
});

export default withTranslation()(SimpleDialogDemo);

SimpleDialogDemo.propTypes = {
  englishBot: PropTypes.bool,
  sessionID: PropTypes.string,
  modalHandler: PropTypes.func,
  setFormType: PropTypes.func,
  FormType: PropTypes.string,
  i18n: PropTypes.object,
};
