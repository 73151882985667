import { PropTypes } from 'prop-types';

const StepperConnector = (props) => {
  const { classprop } = props;
  return (
    <>
      <span className={`start-${classprop}`} />
      <div className="custom-connector" />
      <span className={`end-${classprop}`} />
    </>
  );
};

export default StepperConnector;

StepperConnector.propTypes = {
  classprop: PropTypes.string,
};
