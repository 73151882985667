import { Grid, Select, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { yearGenerator } from '../../utils/CommonFunctions';
import { AREA_LABEL_DOB_YEAR } from '../../../../Constants';

const useStyles = CommonStyles();

const DateYear = (props) => {
  const { t } = useTranslation();
  const { handleDateOfBirthChangeDropdown } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, handleBlur } = formikContext;
  return (
    <Grid item xs={3} sm={3} className="dob-select">
      <Select
        id="dob_year"
        name="dob_year"
        variant="outlined"
        role="listbox"
        aria-label={`${t(AREA_LABEL_DOB_YEAR, {
          defaultValue: AREA_LABEL_DOB_YEAR,
        })}`}
        fullWidth
        className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_month_year_select} ${CommonCssStyles.input_disabled_style} `}
        onBlur={handleBlur}
        onChange={(e) => handleDateOfBirthChangeDropdown(e)}
        value={values.dob_year}
        classes={{
          icon: CommonCssStyles.select_icon,
        }}
      >
        <MenuItem value="YYYY" role="option">
          {t('YYYY', { defaultValue: 'YYYY' })}
        </MenuItem>
        {yearGenerator().length !== 0
          ? yearGenerator().map((year) => (
              <MenuItem key={year} role="option" value={year}>
                {year}
              </MenuItem>
            ))
          : ''}
      </Select>
    </Grid>
  );
};
DateYear.propTypes = {
  handleDateOfBirthChangeDropdown: PropTypes.func,
};

export default DateYear;
