import React, { useState } from 'react';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { stepperStyles, stepperColorlibStepIconStyles } from './StepperStyles';
import StepperConnector from './StepperConnector';
import { CommonStyles } from '../CommonStyles';
import { validationSchema } from '../FormModel/validationSchema';
import { formInitialValues } from '../FormModel/formInitialValues';
import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import StepThree from '../StepThree';
import ThankYouPage from './ThankYouPage';
import ErrorPage from './ErrorPage';
import GenerateLexUserId from '../../../../connectivity/GenerateLexUserId';
import GenerateLexUserCookie from '../../../../connectivity/GenerateLexUserCookie';
import { base64Authorization } from '../../utils/CommonFunctions';
import {
  BACK_ENG,
  CONTINUE,
  ENG,
  ESP,
  HEADER_DESCRIPTION,
  PAYOFF_STATEMENT_REQUEST,
  SEND_ENG,
} from '../../../../Constants';

const lexUserCookie = GenerateLexUserCookie();
const lexUserId = GenerateLexUserId();
const useStyles = stepperStyles();
const useCommonStyles = CommonStyles();

const useColorlibStepIconStyles = stepperColorlibStepIconStyles();

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { icon, active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  icon: PropTypes.number,
  active: PropTypes.number,
  completed: PropTypes.number,
};

const stepList = ['Request Details', 'About you', 'Consent'];

export default function StepperWizard(props) {
  const { englishBot, onClose, webFormType, sessionID, languageLinkHandler, steps } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <StepOne englishBot={englishBot} />;
      case 1:
        return <StepTwo englishBot={englishBot} stepBackFlag={stepBackFlag} />;
      case 2:
        return <StepThree englishBot={englishBot} webFormType={webFormType} />;
      default:
        return 'Unknown stepIndex';
    }
  }
  const classes = useStyles();
  const CommonCssStyles = useCommonStyles();
  const { t } = useTranslation();
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === stepList.length - 1;
  const todayFormattedDate = moment().format('MM/DD/YYYY h:mm A');
  const [successData, setSuccessData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [stepBackFlag, setStepBackFlag] = useState(false);

  async function submitForm(values, actions) {
    sendData(values, actions);
  }
  const getRequestPurpose = (values) => {
    if (values.purpose && values.purpose !== 'Other') {
      return t(values.purpose, { defaultValue: values.purpose });
    }
    if (values.otherPurpose && values.specificReason === '') {
      return t(values.otherPurpose, { defaultValue: values.otherPurpose });
    }
    return values.specificReason;
  };
  const sendData = (values, actions) => {
    const effectiveDateArray = values.effective_date.split(' ');
    let DOBDateArray = '';
    if (values.dob_date) {
      DOBDateArray = values.dob_date.split(' ');
    }
    const params = {
      userId: lexUserId,
      sessionId: sessionID,
      botType: englishBot === true ? ENG : ESP,
      webFormType,
      chatbotktc: lexUserCookie,
      RequestPurpose: getRequestPurpose(values),
      PayoffEffectiveDate: `${effectiveDateArray[0]} ${t(effectiveDateArray[1], {
        defaultValue: effectiveDateArray[1],
      })} ${effectiveDateArray[2]}`,
      ReciverType: values.recievePerson,
      personalDetails: {
        OwnerDetails: {
          FirstName: values.firstName,
          Initial: values.middleName,
          LastName: values.lastName,
          MotherLastName: values.mothersLastName,
          LoanNumber: values.loanNumber,
          SocialSecurityNumber: values.socialSecurityNumber,
          Mobile: `(${values.mobileNumberAreacode}) ${values.mobileNumber}`,
          AlternateNo: `(${values.altMobileNumberAreacode}) ${values.altMobileNumber}`,
          DOB: values.dob_date
            ? `${DOBDateArray[0]} ${t(DOBDateArray[1], { defaultValue: DOBDateArray[1] })} ${
                DOBDateArray[2]
              }`
            : '',
        },
        AuthorizerDetails: {
          AuthFirstName: values.otherPersonFirstName,
          AuthInitial: values.otherPersonMiddleName,
          AuthLastName: values.otherPersonLastName,
          AuthMotherLastName: values.otherMothersLastName,
          Relationship: values.otherPersonRelationship,
          RelationshipType: values.withOtherPersonRelationShipType,
          companyName: values.otherPersonCompanyName,
          LicenseNumber: values.otherPersonLicenseNumber,
          Mobile: `(${values.otherPersonMobileNumberAreacode}) ${values.otherPersonMobileNumber}`,
          AlternateNo: `(${values.otherPersonAltMobileNumberAreacode}) ${values.otherPersonAltMobileNumber}`,
        },
        email: values.personEmail,
        AddressDetails: {
          Address1: values.address1,
          Address2: values.address2,
          City: values.city,
          State: values.state,
          Zip: values.zipCode,
          Country: values.country,
        },
      },
      RequestDate: todayFormattedDate,
      ContactType: values.sentMethod,
    };
    languageLinkHandler();
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL,
        {
          clientId: process.env.REACT_APP_CLIENT_ID,
        },
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        axios
          .post(`${process.env.REACT_APP_BALCANCEL_FORM_URL}`, params, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            const { data } = res;
            setSuccessData(data);
            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
            setShowLoader(false);
          })
          .catch((err) => {
            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
            setShowLoader(false);
          });
      })
      .catch();
  };

  const handleSubmitFormik = (values, actions) => {
    if (isLastStep) {
      setShowLoader(true);
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setStepBackFlag(true);
  };
  return (
    <div className={classes.root}>
      <div>
        {activeStep === stepList.length ? (
          <div>
            {!successData.CaseError && <ThankYouPage successData={successData} onClose={onClose} />}
            {successData.CaseError && <ErrorPage errorData={successData} onClose={onClose} />}
          </div>
        ) : (
          <>
            <Grid item className={CommonCssStyles.stepper_margin}>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  id="dialog1Title"
                  className={CommonCssStyles.form_heading_info}
                >
                  {t(PAYOFF_STATEMENT_REQUEST, { defaultValue: PAYOFF_STATEMENT_REQUEST })}
                </Typography>
                <Typography
                  variant="div"
                  className={CommonCssStyles.form_heading_desc_info}
                  tabIndex="-1"
                >
                  {t(HEADER_DESCRIPTION, { defaultValue: HEADER_DESCRIPTION })}
                </Typography>
              </Grid>
            </Grid>
            <Stepper alternativeLabel activeStep={activeStep} className={classes.zero_Padding}>
              {stepList.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <StepperConnector classprop={`step-${index}`} />
                    <div aria-label={`${label}`} tabIndex={label === stepList[steps] ? '0' : ''}>
                      {t(label, { defaultValue: label })}
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {showLoader && (
              <div className={CommonCssStyles.progress_icon}>
                <CircularProgress size={60} style={{ color: '#E37617' }} />
              </div>
            )}
            {!showLoader && (
              <Formik
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                // validateOnMount
                onSubmit={handleSubmitFormik}
              >
                {({ handleSubmit, isSubmitting, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      item
                      xs={12}
                      className={CommonCssStyles.steps_grid}
                      style={{ margin: '10px' }}
                    >
                      {getStepContent(activeStep)}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={CommonCssStyles.buttons_grid}
                      style={{ justifyContent: 'flex-end' }}
                    >
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={CommonCssStyles.back_button}>
                          {t(BACK_ENG, { defaultValue: BACK_ENG })}
                        </Button>
                      )}
                      {isLastStep ? (
                        values.consent &&
                        values.consentEmail && (
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            data-testid="submittype_testid"
                            variant="contained"
                            color="primary"
                            className={CommonCssStyles.continue_button}
                          >
                            {t(SEND_ENG, { defaultValue: SEND_ENG })}
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          data-testid="submit_testid"
                          variant="contained"
                          color="primary"
                          className={CommonCssStyles.continue_button}
                        >
                          {t(CONTINUE, { defaultValue: CONTINUE })}
                        </Button>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
    </div>
  );
}

StepperWizard.propTypes = {
  englishBot: PropTypes.bool,
  steps: PropTypes.string,
  onClose: PropTypes.func,
  webFormType: PropTypes.string,
  sessionID: PropTypes.string,
  languageLinkHandler: PropTypes.func,
};
