import { Grid, Button } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import LanguageIcon from './img/languageIcon2.png';
import CloseIcon from './img/CloseIcon.png';
import { topHeaderStyles } from './TopHeaderStyles';
import { ENG, ESP } from '../../../../Constants';

const useStyles = topHeaderStyles();

export default function TopHeader(props) {
  const styledClasses = useStyles();
  const { englishBot, handleModalLanguageChange, handleClose, languageFlag } = props;
  const [languageString, setLanguageString] = useState(englishBot ? ESP : ENG);

  useEffect(() => {
    if (englishBot) {
      setLanguageString(ESP);
    } else {
      setLanguageString(ENG);
    }
  }, [englishBot]);

  const sendLanguage = () => {
    if (englishBot) {
      setLanguageString(ESP);
      handleModalLanguageChange(ESP);
    } else {
      setLanguageString(ENG);
      handleModalLanguageChange(ENG);
    }
  };

  return (
    <div>
      <Grid container className={styledClasses.top_header}>
        {languageFlag && (
          <Button
            onClick={sendLanguage}
            data-testid="topHeader_btnid"
            className={styledClasses.lang_change_button}
            role="button"
            aria-label="Language Switch"
          >
            <img
              src={LanguageIcon}
              alt="Language Icon"
              aria-label="Language Icon"
              className={styledClasses.language_icon}
            />
            {languageString === ENG ? ENG : ESP}
          </Button>
        )}
        <Button
          onClick={handleClose}
          className={styledClasses.lang_change_button}
          role="button"
          data-testid="closebtn_testid"
          aria-label="Close"
        >
          <img
            src={CloseIcon}
            alt="Close Icon"
            aria-label="Close Icon"
            className={styledClasses.close_button}
          />
        </Button>
      </Grid>
    </div>
  );
}

TopHeader.propTypes = {
  englishBot: PropTypes.bool,
  handleModalLanguageChange: PropTypes.func,
  handleClose: PropTypes.func,
  languageFlag: PropTypes.bool,
};
