import ReactHtmlParser from 'react-html-parser';
import { PropTypes } from 'prop-types';
import GenerateLexUserId from '../../../connectivity/GenerateLexUserId';
import { GREET_INTENT, KNOW_TO_CHAT_NO } from '../../../Constants';

const lexUserId = GenerateLexUserId();
function QuickReplies(props) {
  const { options, onSelect } = props;
  let buttonText = '';
  const loadMoreBtn = {
    background: '#661c69',
    color: '#fff',
  };
  const loadMoreBtnEmpty = {};
  return (
    <div
      className="quick-replies"
      role="list"
      style={{
        display: 'block',
        justifyContent: 'left',
        textAlign: 'left',
      }}
    >
      {options.map((option) => {
        let tab = '';
        const buttonStyle =
          option.value === `${lexUserId}*LoadMore` ? loadMoreBtn : loadMoreBtnEmpty;
        if (option?.value?.toLowerCase().startsWith('http')) {
          tab = 'button opens in a new tab';
        }
        if (
          option?.text?.includes('(Status - Open)') ||
          option?.text?.includes('(Estatus - Abierto)') ||
          option?.text?.includes('(Status - Cancelled)') ||
          option?.text?.includes('(Status - Completed)') ||
          option?.text?.includes('(Estatus - Cancelado)') ||
          option?.text?.includes('(Estatus - Completado)')
        ) {
          const buttonArr = option.text.split('<br/>');
          buttonText = `${buttonArr[0]}<br/><span style="color:#F5821F;">${buttonArr[1]}</span>`;
        } else {
          buttonText = '';
        }
        if (option.value === KNOW_TO_CHAT_NO || option.value === GREET_INTENT) {
          return (
            <button
              type="button"
              aria-label={`${option.text} ${tab}`}
              tabIndex="102"
              style={buttonStyle}
              key={option.text}
              onClick={() => onSelect(option.text, option.value)}
              data-testid="onSelect_testid"
              className={`${option.className} greet-button-cls quick-reply-button`}
            >
              {ReactHtmlParser(option.text)}
            </button>
          );
        }
        if (
          (option?.text?.includes('(Status - Open)') ||
            option?.text?.includes('(Estatus - Abierto)') ||
            option?.text?.includes('(Status - Cancelled)') ||
            option?.text?.includes('(Status - Completed)') ||
            option?.text?.includes('(Estatus - Cancelado)') ||
            option?.text?.includes('(Estatus - Completado)')) &&
          buttonText !== ''
        ) {
          return (
            <button
              type="button"
              disabled={option.value === 'SSN_Number'}
              aria-label={`${option.text} ${tab}`}
              tabIndex="102"
              data-testid="quickReplies_testid"
              style={buttonStyle}
              key={option.text}
              onClick={() => onSelect(option.text, option.value)}
              className={`quick-reply-button ${option.className}`}
            >
              {ReactHtmlParser(buttonText)}
            </button>
          );
        }
        return (
          <button
            type="button"
            disabled={option.value === 'SSN_Number'}
            aria-label={`${option.text} ${tab}`}
            tabIndex="102"
            style={buttonStyle}
            key={option.text}
            data-testid="quickReplies_testid"
            onClick={() => onSelect(option.text, option.value)}
            className={`quick-reply-button ${option.className}`}
          >
            {ReactHtmlParser(option.text)}
          </button>
        );
      })}
    </div>
  );
}
QuickReplies.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
};
export default QuickReplies;
