import { Grid, Select, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import { dateGenerator } from '../../utils/CommonFunctions';
import { ARIA_LABEL_DOB_DAY, DD } from '../../../../Constants';

const useStyles = CommonStyles();

const DateDay = (props) => {
  const { t } = useTranslation();
  const { handleDateOfBirthChangeDropdown } = props;
  const CommonCssStyles = useStyles();
  const formikContext = useFormikContext();
  const { values, handleBlur } = formikContext;
  return (
    <Grid item xs={3} sm={3} className="dob-select-day">
      <Select
        id="dob_day"
        name="dob_day"
        variant="outlined"
        role="listbox"
        fullWidth
        aria-label={`${t(ARIA_LABEL_DOB_DAY, {
          defaultValue: ARIA_LABEL_DOB_DAY,
        })}`}
        className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_day_select} ${CommonCssStyles.input_disabled_style} `}
        value={values.dob_day}
        onBlur={handleBlur}
        onChange={(e) => handleDateOfBirthChangeDropdown(e)}
        data-testid="slectBirthday_testid"
        classes={{
          icon: CommonCssStyles.select_icon,
        }}
      >
        <MenuItem value="DD" role="option">
          {t(DD, { defaultValue: DD })}
        </MenuItem>
        {dateGenerator().length !== 0
          ? dateGenerator().map((date) => (
              <MenuItem key={date} role="option" value={date}>
                {date}
              </MenuItem>
            ))
          : ''}
      </Select>
    </Grid>
  );
};
DateDay.propTypes = {
  handleDateOfBirthChangeDropdown: PropTypes.func,
};

export default DateDay;
