import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  agent_feedback: {
    '& .MuiTextField-root': {
      width: '230px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 5px 10px 10px',
      fontSize: '14px',
      border: '1px solid #661c69',
      '&:focus': {
        outline: 'none !important',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiFormHelperText-contained': {
      textAlign: 'right',
      fontWeight: 'bolder',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.70)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  agent_container: { alignItems: 'center', width: '100%' },
  agent_padding: { padding: '0.5em' },
  agent_marginall: { margin: '12px 0 15px 0' },
  agent_row: { display: 'block', textAlign: 'center' },
}));
