import { DD, MMM, YYYY } from '../../../../Constants';

export const formInitialValues = {
  purpose: '',
  otherPurpose: '',
  specificReason: '',
  effective_day: DD,
  effective_month: MMM,
  effective_year: YYYY,
  effective_date: '',
  recievePerson: 'own',
  firstName: '',
  middleName: '',
  lastName: '',
  mothersLastName: '',
  loanNumber: '',
  socialSecurityNumber: '',
  mobileNumberAreacode: '787',
  mobileNumber: '',
  altMobileNumberAreacode: '787',
  altMobileNumber: '',
  dob_day: DD,
  dob_month: MMM,
  dob_year: YYYY,
  dob_date: '',
  otherPersonFirstName: '',
  otherPersonMiddleName: '',
  otherPersonLastName: '',
  otherMothersLastName: '',
  otherPersonRelationship: 'Lawyer',
  otherPersonCompanyName: '',
  withOtherPersonRelationShipType: '',
  otherPersonLicenseNumber: '',
  otherPersonMobileNumberAreacode: '787',
  otherPersonMobileNumber: '',
  otherPersonAltMobileNumberAreacode: '787',
  otherPersonAltMobileNumber: '',
  sentMethod: 'email',
  personEmail: '',
  address1: '',
  address2: '',
  country: 'PR',
  state: '',
  city: '',
  zipCode: '',
  consent: false,
  consentEmail: false,
};

export const formInitialValuesForServiceToMyAccount = {
  firstName: '',
  lastName: '',
  socialSecurityNumber: '',
  mobileNumberAreacode: '787',
  mobileNumber: '',
  dob_day: DD,
  dob_month: MMM,
  dob_year: YYYY,
  dob_date: '',
  personEmail: '',
  accountType: '',
  accountNumber: '',
};
export const formInitialValuesForGeneralInfo = {
  firstName: '',
  lastName: '',
  mobileNumberAreacode: '787',
  mobileNumber: '',
  personEmail: '',
  accountType: '',
};
