import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
  },
  formControl: {
    margin: '12px',
  },
  main_label: {
    '&.MuiFormLabel-root': {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.11px',
      color: '#000000',
      padding: '10px 0',
    },
  },
  tooltip: {
    background: '#f2f2f2',
    color: '#000',
    border: '0.5px solid #707070',
    borderRadius: '5px',
    font: 'normal normal 300 14px/16px Arial',
  },
  arrow: {
    fontSize: 18,
    width: 20,
    '&::before': {
      border: '0.5px solid #707070',
      backgroundColor: '#f2f2f2',
      boxSizing: 'border-box',
    },
  },
  tooltipUpload: {
    background: '#fff',
    color: '#000',
    border: '1px solid #F5821F',
    // marginTop: "15px",
    padding: '10px',
    borderRadius: '5px',
    font: 'normal normal 300 16px/16px Arial',
  },
  arrowUpload: {
    fontSize: 18,
    width: 20,
    '&::before': {
      border: '1px solid #F5821F',
      backgroundColor: 'rgba(245, 130, 31, 0.1)',
      boxSizing: 'border-box',
    },
  },
  radio_label: {
    '& .MuiTypography-body1': {
      font: 'normal normal 300 14px/16px Arial',
      paddingLeft: '8px',
    },
    '&.MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
      width: '0.8em',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#661C99',
    },
    '& .MuiRadio-root': {
      padding: '3px',
    },
  },
  radio_input: {
    '& .MuiInputBase-input': {
      font: 'normal normal 300 14px/16px Arial',
      padding: '6px 6px 2px',
      lineHeight: '1.5em',
    },
    '& .MuiInput-underline:before': {
      border: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInputBase-root': {
      lineHeight: '1.5em',
    },
  },
}));

export const DATEPICKERTHEME = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F4F4F4',
        opacity: 1,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
      toolbarBtnSelected: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#661C69',
        opacity: 1,
      },
      dayLabel: {
        opacity: 1,
        fontFamily: 'Arial',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '225px',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: '#661C69',
        opacity: 1,
      },
    },
    MuiPickersDay: {
      day: {
        color: '#661C69',
        opacity: 1,
      },
      daySelected: {
        backgroundColor: '#661C69',
        opacity: 1,
        '&:hover': {
          backgroundColor: '#661C69',
        },
      },
      current: {
        color: '#661C69',
        opacity: 1,
      },
    },
  },
});
