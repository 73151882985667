import { PropTypes } from 'prop-types';
import { IconButton } from '@livechat/ui-kit';
import { ENG } from '../../../Constants';

const ChangeLanguage = (props) => {
  const { handleLanguageChangeYesButtonClick, handleLanguageChangeNo, langValue } = props;
  const defaultContent = langValue === ENG ? 'Sí' : 'Yes';
  return (
    <div
      className="quick-replies"
      style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
    >
      <IconButton
        onClick={handleLanguageChangeYesButtonClick}
        tabIndex="102"
        data-testid="yesButtonClick_id"
      >
        {defaultContent}
      </IconButton>
      <IconButton onClick={handleLanguageChangeNo} tabIndex="102">
        No
      </IconButton>
    </div>
  );
};

ChangeLanguage.propTypes = {
  handleLanguageChangeYesButtonClick: PropTypes.func,
  handleLanguageChangeNo: PropTypes.func,
  langValue: PropTypes.string,
};
export default ChangeLanguage;
