import { PropTypes } from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CommonStyles } from '../CommonStyles';
import { CLOSE_AND_RETURN_TO_CHAT, THANKYOU_HEADER } from '../../../../Constants';
import ThankYouImg from '../../images/ThankYou_Header.png';

const useCommonStyles = CommonStyles();
function ThankYouPage(props) {
  const { t } = useTranslation();
  const CommonCssStyles = useCommonStyles();
  const { successData, onClose } = props;
  return (
    <div>
      <Grid container item xs={12}>
        <Grid container item>
          <Grid item container className={CommonCssStyles.main_header}>
            <img
              src={ThankYouImg}
              className={CommonCssStyles.thank_you_header}
              tabIndex="-1"
              alt={t(THANKYOU_HEADER, { defaultValue: THANKYOU_HEADER })}
              aria-label={t(THANKYOU_HEADER, { defaultValue: THANKYOU_HEADER })}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Typography
              id="dialog1Title"
              variant="div"
              className={CommonCssStyles.thankyou_heading}
              style={{ margin: '15px' }}
            >
              {successData.MessageHeader}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              id="dialog1Desc"
              className={CommonCssStyles.thankyou_info}
              style={{ margin: '22px 15px' }}
            >
              {successData.MessageBody}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} className={CommonCssStyles.buttons_grid_return}>
        <Button onClick={onClose} className={CommonCssStyles.return_button}>
          {t(CLOSE_AND_RETURN_TO_CHAT, { defaultValue: CLOSE_AND_RETURN_TO_CHAT })}
        </Button>
      </Grid>
    </div>
  );
}

ThankYouPage.propTypes = {
  successData: PropTypes.object,
  onClose: PropTypes.func,
};

export default ThankYouPage;
