import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { CommonStyles } from '../CommonStyles';
import ThankYouHeader from '../../images/ThankYou_Header.png';
import { DOWNLOAD_PAYOFF_FORM, ERROR_HEADER, GO_TO_ORIENTALBANK_COM } from '../../../../Constants';

const useCommonStyles = CommonStyles();
function ErrorPage(props) {
  const { t } = useTranslation();
  const CommonCssStyles = useCommonStyles();
  const { errorData } = props;
  return (
    <div>
      <Grid container item xs={12}>
        <Grid container item>
          <Grid item container className={CommonCssStyles.main_header}>
            <img
              src={ThankYouHeader}
              className={CommonCssStyles.thank_you_header}
              tabIndex="-1"
              alt={t(ERROR_HEADER, { defaultValue: ERROR_HEADER })}
              aria-label={t(ERROR_HEADER, { defaultValue: ERROR_HEADER })}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Typography
              variant="div"
              id="dialog1Title"
              className={CommonCssStyles.thankyou_heading}
              style={{ margin: '15px' }}
            >
              {errorData.MessageHeader}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              id="dialog1Desc"
              className={CommonCssStyles.thankyou_info}
              style={{ margin: '22px 15px' }}
            >
              {errorData.MessageBody}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={12} className={CommonCssStyles.buttons_grid_error}>
        <a
          href={errorData.FormUrl}
          role="button"
          aria-label={t(DOWNLOAD_PAYOFF_FORM, { defaultValue: DOWNLOAD_PAYOFF_FORM })}
          target="_blank"
          rel="noreferrer"
          className={CommonCssStyles.pdf_button}
        >
          {/* <Button className={CommonCssStyles.pdf_button}> */}
          {t(DOWNLOAD_PAYOFF_FORM, { defaultValue: DOWNLOAD_PAYOFF_FORM })}
          {/* </Button> */}
        </a>
        <a
          href="https://orientalbank.com/"
          role="button"
          aria-label={t(GO_TO_ORIENTALBANK_COM, { defaultValue: GO_TO_ORIENTALBANK_COM })}
          target="_blank"
          rel="noreferrer"
          className={CommonCssStyles.bank_site_btn}
        >
          {/* <Button> */}
          {t(GO_TO_ORIENTALBANK_COM, { defaultValue: GO_TO_ORIENTALBANK_COM })}
          {/* </Button> */}
        </a>
      </Grid>
    </div>
  );
}
ErrorPage.propTypes = {
  errorData: PropTypes.object,
};
export default ErrorPage;
