import { v4 as uuidv4 } from 'uuid';

export default function GenerateLexUserCookie() {
  const name = 'chatbotktc=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const elems = decodedCookie.split(';');
  for (const element of elems) {
    let item = element;
    while (item.charAt(0) === ' ') {
      item = item.substring(1);
    }
    if (item.indexOf(name) === 0) {
      return item.substring(name.length, item.length);
    }
  }
  const UUID = uuidv4();
  const daysToExpire = new Date(2147483647 * 1000).toUTCString();
  document.cookie = `chatbotktc=${UUID}; expires=${daysToExpire}; HttpOnly; Secure; SameSite=None`;
  return UUID;
}
