import { useState } from 'react';
import { SvgIcon, createTheme, ClickAwayListener } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { PropTypes } from 'prop-types';
import { IconButton } from '@livechat/ui-kit';
import { ReactComponent as DatePickerIcon } from '../images/datepicker.svg';
import { CHOOSE_START_DATE_ENG, CHOOSE_START_DATE_ESP, DATE_PICKER_ICON } from '../../../Constants';
import { CommonStyles } from '../balance-cancellation/CommonStyles';

const useCommonStyles = CommonStyles();

const StartCalendar = (props) => {
  const CommoncssStyles = useCommonStyles();

  const { onStartDateSelectHandler, startDateTravel, englishBot, message } = props;
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(true);
  const [dateValue, setDateValue] = useState('');
  const today = new Date();
  const lunguage = englishBot ? enLocale : esLocale;
  const buttonText = englishBot ? CHOOSE_START_DATE_ENG : CHOOSE_START_DATE_ESP;
  const handleClickOpenStartDate = () => {
    if (showStartDateCalendar) {
      setShowStartDateCalendar(false);
    } else {
      setShowStartDateCalendar(true);
    }
  };
  const handleClickAway = () => {
    setShowStartDateCalendar(false);
  };
  setTimeout(() => {
    const messageContainer = document.getElementById('messageContainer');
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
    }
  }, 1000);
  const handleDate = (value) => {
    const formattedDate = format(value, 'dd/MM/yyyy');
    setDateValue(formattedDate);
    onStartDateSelectHandler(formattedDate);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway} data-testid="onClickAway_id">
      <div
        className="calendar"
        style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
      >
        <IconButton
          className="datePickerbtn"
          onClick={handleClickOpenStartDate}
          data-testid="openStartDate_testid"
          tabIndex="102"
          disabled={!!(startDateTravel || message.dateReceived)}
        >
          <span className={CommoncssStyles.button_text}>{buttonText}</span>
          <SvgIcon
            aria-label={DATE_PICKER_ICON}
            inputProps={{ tabIndex: '102' }}
            role="graphic"
            titleAccess="Date Picker Icon"
            component={DatePickerIcon}
            viewBox="0 0 29 29"
            className={CommoncssStyles.date_icon}
          />
        </IconButton>
        {showStartDateCalendar && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lunguage}>
            <ThemeProvider theme={DATEPICKERTHEME}>
              <DatePicker
                autoOk
                inputProps={{ tabIndex: '102' }}
                variant="static"
                minDate={today}
                value={dateValue}
                onChange={(value) => {
                  handleDate(value);
                  handleClickOpenStartDate();
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}
      </div>
    </ClickAwayListener>
  );
};
const DATEPICKERTHEME = createTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        left: '6px',
        bottom: '10.8rem',
        zIndex: '1000',
      },
    },
    MuiPickersBasePicker: {
      container: {
        width: '100%',
        flexDirection: 'column !important',
        border: '1px solid #661c99',
        '@media (min-width: 770px) and (max-width:1024px)': {
          width: '99%',
        },
        '@media (max-width:375px)': {
          width: '98%',
        },
        '@media (max-width:360px)': {
          width: '94%',
        },
        '@media (max-width:320px)': {
          width: '81%',
        },
        '@media (max-width:280px)': {
          width: '70%',
        },
      },
      pickerView: {
        minWidth: '100%',
        '@media (max-width:500px)': {
          minHeight: '258px',
        },
        '@media (max-width:320px)': {
          minHeight: '258px',
        },
        '@media (max-width:280px)': {
          minHeight: '210px',
        },
      },
    },
    MuiTypography: {
      h4: {
        '@media (max-width:500px)': {
          fontSize: '1.3rem',
        },
      },
      body2: {},
    },
    MuiPickersCalendar: {
      transitionContainer: {
        '@media (max-width:500px)': {
          minHeight: '190px',
          marginTop: 0,
        },
        '@media (max-width:280px)': {
          minHeight: '170px',
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
      toolbarBtnSelected: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F4F4F4',
        opacity: 1,
        '@media (max-width:500px)': {
          height: '55px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        opacity: 1,
        color: '#661C69',
      },
      dayLabel: {
        fontFamily: 'Arial',

        opacity: 1,
        '@media (max-width:500px)': {
          margin: '0 4px',
        },
        '@media (max-width:360px)': {
          margin: '0 2px',
          width: '36px',
        },
        '@media (max-width:320px)': {
          width: '34px',

          margin: '0 0',
        },
      },
      switchHeader: {
        '@media (max-width:500px)': {
          marginBottom: 0,

          marginTop: 0,
        },
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        opacity: 1,

        color: '#661C69',
      },
    },
    MuiPickersDay: {
      day: {
        opacity: 1,
        color: '#661C69',
        '@media (max-width:500px)': {
          width: '32px',
          height: '32px',
          margin: '0 6px',
        },
        '@media (max-width:360px)': {
          margin: '0 4px',
        },
        '@media (max-width:320px)': {
          height: '32px',
          width: '32px',
          margin: '0 1px',
        },
        '@media (max-width:280px)': {
          width: '28px',
          height: '28px',
        },
      },
      daySelected: {
        backgroundColor: '#661C69',
        opacity: 1,
        '&:hover': {
          backgroundColor: '#661C69',
        },
      },
      current: {
        color: '#661C69',
        opacity: 1,
      },
    },
  },
});

StartCalendar.propTypes = {
  englishBot: PropTypes.bool,
  onStartDateSelectHandler: PropTypes.func,
  startDateTravel: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default StartCalendar;
