import { useEffect, useState } from 'react';
import { SvgIcon, createTheme, ClickAwayListener } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { IconButton } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import { ReactComponent as DatePickerIcon } from '../images/datepicker.svg';
import { CHOOSE_END_DATE_ENG, CHOOSE_END_DATE_ESP, DATE_PICKER_ICON } from '../../../Constants';
import { CommonStyles } from '../balance-cancellation/CommonStyles';

const useCommonStyles = CommonStyles();

const EndCalendar = (props) => {
  const CommoncssStyles = useCommonStyles();

  const { onEndDateSelectHandler, defaultdate, endDateTravel, englishBot, message } = props;
  let startDateSelected = '';
  if (defaultdate) {
    const startDate = defaultdate.split('/');
    startDateSelected = new Date(
      parseInt(startDate[2], 10),
      parseInt(startDate[1] - 1, 10),
      parseInt(startDate[0], 10)
    ).setHours(0, 0, 0, 0);
  }
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowEndDateCalendar(true);
    }, 1000);
  }, []);
  const lunguage = englishBot ? enLocale : esLocale;
  const buttonText = englishBot ? CHOOSE_END_DATE_ENG : CHOOSE_END_DATE_ESP;
  const handleClickOpenEndDate = () => {
    if (showEndDateCalendar) {
      setShowEndDateCalendar(false);
    } else {
      setShowEndDateCalendar(true);
    }
  };
  const handleClickAway = () => {
    setShowEndDateCalendar(false);
  };
  setTimeout(() => {
    const messageContainer = document.getElementById('messageContainer');
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
    }
  }, 1000);
  const handleDate = (value) => {
    const formattedDate = format(value, 'dd/MM/yyyy');
    onEndDateSelectHandler(formattedDate);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="calendar"
        style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
      >
        <IconButton
          style={{ width: '98.7%', marginTop: '0.5em', marginLeft: '4px' }}
          className="datePickerbtn endDate-btn"
          onClick={handleClickOpenEndDate}
          data-testid="endDateCalendar_id"
          tabIndex="102"
          disabled={!!(endDateTravel || message.dateReceived)}
        >
          <span className={CommoncssStyles.button_text}>{buttonText}</span>
          <SvgIcon
            aria-label={DATE_PICKER_ICON}
            tabIndex="0"
            role="graphic"
            titleAccess="Date Picker Icon"
            component={DatePickerIcon}
            viewBox="0 0 29 29"
            className={CommoncssStyles.date_icon}
          />
        </IconButton>
        {showEndDateCalendar && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lunguage}>
            <ThemeProvider theme={DATEPICKERTHEME}>
              <DatePicker
                autoOk
                variant="static"
                minDate={startDateSelected}
                value={startDateSelected}
                onChange={(value) => {
                  handleDate(value);
                  handleClickOpenEndDate();
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}
      </div>
    </ClickAwayListener>
  );
};
const DATEPICKERTHEME = createTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        left: '6px',
        bottom: '10.8rem',
        zIndex: '1000',
        marginLeft: '1.2%',
        '@media (max-width:320px)': {
          marginLeft: '0',
        },
      },
    },
    MuiPickersBasePicker: {
      container: {
        width: '100%',
        flexDirection: 'column !important',
        border: '1px solid #661c99',
        '@media (min-width: 770px) and (max-width:1024px)': {
          width: '99%',
        },
        '@media (max-width:375px)': {
          width: '98%',
        },
        '@media (max-width:320px)': {
          width: '81%',
        },
        '@media (max-width:280px)': {
          width: '70%',
        },
      },
      pickerView: {
        minWidth: '100%',
        // minHeight: "350px",
        '@media (max-width:500px)': {
          minHeight: '258px',
        },
        '@media (max-width:320px)': {
          minHeight: '258px',
        },
        '@media (max-width:280px)': {
          minHeight: '210px',
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        '@media (max-width:500px)': {
          minHeight: '190px',
          marginTop: 0,
        },
        '@media (max-width:280px)': {
          minHeight: '170px',
        },
      },
    },
    MuiTypography: {
      h4: {
        '@media (max-width:500px)': {
          fontSize: '1.3rem',
        },
      },
      body2: {},
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F4F4F4',
        opacity: 1,
        '@media (max-width:500px)': {
          height: '55px',
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: 'Arial',
        color: '#000000',
        opacity: 1,
      },
      toolbarBtnSelected: {
        fontFamily: 'Arial',
        color: '#000000',
        opacity: 1,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#661C69',
        opacity: 1,
      },
      dayLabel: {
        opacity: 1,
        fontFamily: 'Arial',
        '@media (max-width:500px)': {
          margin: '0 4px',
        },
        '@media (max-width:360px)': {
          margin: '0 2px',
          width: '36px',
        },
        '@media (max-width:320px)': {
          margin: '0 0',
          width: '34px',
        },
      },
      switchHeader: {
        '@media (max-width:500px)': {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: '#661C69',
        opacity: 1,
      },
    },
    MuiPickersDay: {
      day: {
        color: '#661C69',
        opacity: 1,
        '@media (max-width:500px)': {
          width: '32px',
          height: '32px',
          margin: '0 6px',
        },
        '@media (max-width:360px)': {
          margin: '0 4px',
        },
        '@media (max-width:320px)': {
          width: '32px',
          height: '32px',
          margin: '0 1px',
        },
        '@media (max-width:280px)': {
          width: '28px',
          height: '28px',
        },
      },
      daySelected: {
        backgroundColor: '#661C69',
        opacity: 1,
        '&:hover': {
          backgroundColor: '#661C69',
        },
      },
      current: {
        color: '#661C69',
        opacity: 1,
      },
    },
  },
});

EndCalendar.propTypes = {
  onEndDateSelectHandler: PropTypes.func,
  defaultdate: PropTypes.string,
  endDateTravel: PropTypes.string,
  englishBot: PropTypes.bool,
  message: PropTypes.object,
};
export default EndCalendar;
