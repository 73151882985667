import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import { InputAdornment } from '@material-ui/core';
import { IconButton } from '@livechat/ui-kit';
import { TextInput } from './TextInput';
import hideIcon from '../../images/eyeIconHide.png';
import showIcon from '../../images/eyeIcon.png';
import { SHOW_HIDE_ICON } from '../../../../Constants';
import { CommonStyles } from '../CommonStyles';

const useCommonStyles = CommonStyles();

export default function SSNMask() {
  const CommoncssStyles = useCommonStyles();

  const formikContext = useFormikContext();
  const { values, setFieldValue, setFieldTouched } = formikContext;
  const [ssnval, setSssnVal] = React.useState('true');
  const [showHideIcon, setShowHideIcon] = useState(hideIcon);
  const showHideHandler = (e) => {
    e.preventDefault();
    setSssnVal((ssnVal) => !ssnVal);
    const icon = showHideIcon === hideIcon ? showIcon : hideIcon;
    setShowHideIcon(icon);
  };
  React.useEffect(() => {
    const { socialSecurityNumber } = values;

    const len = socialSecurityNumber?.length;
    let stars;
    if (len === 0) stars = '';
    if (len === 1) stars = 'x';
    if (len === 2) stars = 'xx';
    if (len === 3) stars = 'xxx';
    if (len === 4) stars = 'xxx-';
    if (len === 5) stars = 'xxx-x';
    if (len === 6) stars = 'xxx-xx';
    if (len === 7) stars = 'xxx-xx-';
    if (len >= 8) stars = 'xxx-xx-';

    // eslint-disable-next-line no-unsafe-optional-chaining
    const result = stars + socialSecurityNumber?.substring(7);
    setFieldValue('ssnFakeValue', result);
  }, [values.socialSecurityNumber]);
  const arrowLeftRight = (e, selectionStart) => {
    if (e.key === 'ArrowLeft') {
      if (selectionStart === 0) return;
      setTimeout(() => {
        e.target.selectionEnd = selectionStart - 1;
      }, 10);
    } else if (e.key === 'ArrowRight') {
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 1;
      }, 10);
    }
  };
  const ssnValueDash = (ssnValue, selectionStart) => {
    let ssnVal = ssnValue;
    if (ssnVal.toString().length >= 4) {
      ssnVal = `${ssnVal.slice(0, 3)}-${ssnVal.slice(3, ssnVal.toString().length)}`;
    }
    if (ssnVal.toString().length >= 7) {
      ssnVal = `${ssnVal.slice(0, 6)}-${ssnVal.slice(6, ssnVal.toString().length)}`;
    }
    setTimeout(() => {
      const input = document.getElementById('socialSecurityNumber');
      if (input.setSelectionRange) {
        input.focus();
        if (selectionStart + 1 === 4 || selectionStart + 1 === 7) {
          input.setSelectionRange(selectionStart + 2, selectionStart + 2);
        } else {
          input.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }
      }
    }, 10);
    return ssnVal;
  };
  const delValueDash = (e, delValue, selectionStart, selectionEnd) => {
    let delVal = delValue;
    if (delVal.toString().length >= 4) {
      delVal = `${delVal.slice(0, 3)}-${delVal.slice(3, delVal.toString().length)}`;
    }
    if (delVal.toString().length >= 7) {
      delVal = `${delVal.slice(0, 6)}-${delVal.slice(6, delVal.toString().length)}`;
    }
    setTimeout(() => {
      if (selectionStart !== selectionEnd) {
        e.target.selectionEnd = selectionStart;
      } else {
        e.target.selectionEnd = selectionStart - 1;
      }
    }, 10);
    return delVal;
  };
  const maskValueChange = (e) => {
    if (e.key === 'Tab' || e.key === 'Unidentified') {
      return;
    }
    e.preventDefault();

    const numkeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const navkeys = [
      'Backspace',
      'Delete',
      'Shift',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    const { selectionStart } = e.target;
    const { selectionEnd } = e.target;
    arrowLeftRight(e, selectionStart);

    if (!numkeys.includes(e.key) && !navkeys.includes(e.key)) {
      e.preventDefault();
    } else if (values.socialSecurityNumber.length < 11 && numkeys.includes(e.key)) {
      const enterValue =
        values.socialSecurityNumber.substring(0, selectionStart) +
        e.key +
        values.socialSecurityNumber.substring(selectionEnd);
      let ssnValue = values.socialSecurityNumber !== '' ? enterValue : e.key;
      ssnValue = ssnValue.replace(/-/g, '');
      ssnValue = ssnValueDash(ssnValue, selectionStart);
      setFieldValue('socialSecurityNumber', ssnValue);
      setTimeout(() => {
        setFieldTouched('socialSecurityNumber', true);
      }, 100);
      return false;
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      let delValue;
      if (selectionEnd === 0) {
        return;
      }
      if (selectionStart === selectionEnd) {
        delValue =
          values.socialSecurityNumber.slice(0, selectionStart - 1) +
          values.socialSecurityNumber.slice(selectionEnd, values.socialSecurityNumber.length);
      } else {
        delValue =
          values.socialSecurityNumber.substring(0, selectionStart) +
          values.socialSecurityNumber.substring(selectionEnd);
      }
      delValue = delValue.replace(/-/g, '');

      delValue = delValueDash(e, delValue, selectionStart, selectionEnd);
      setFieldValue('socialSecurityNumber', delValue);
      setTimeout(() => {
        setFieldTouched('socialSecurityNumber', true);
      }, 100);
    }
    return false;
  };

  return (
    <div>
      <Field
        // type="text"
        labelrequired="true"
        inputProps={{
          maxLength: 11,
          inputMode: isMobile || isTablet ? 'numeric' : 'text',
          'data-testid': 'ssn_testid',
        }}
        name="socialSecurityNumber"
        id="socialSecurityNumber"
        value={ssnval ? values.ssnFakeValue : values.socialSecurityNumber}
        label="Social Security Number"
        onKeyDown={maskValueChange}
        onBlur={maskValueChange}
        onChange={maskValueChange}
        customplaceholder="Enter social security number"
        component={TextInput}
        endAdornment={
          <InputAdornment position="end" className={CommoncssStyles.ssn_mark_input}>
            <IconButton onClick={showHideHandler}>
              <img
                alt={SHOW_HIDE_ICON}
                src={showHideIcon}
                className={CommoncssStyles.ssn_mark_margin}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
}
